.row{
	margin-bottom:10px;
}
.hidden{
	display: none !important;
}

body{
	margin-bottom:0;
}
.accounts{
	background: #f1f1f1;
	.profile{
		background-color: $color-primary;
		padding: 30px 15px;
		position: relative;
		.pr-image{
			width: 70px;
			height: 70px;
			background-size: cover;
			background-repeat: no-repeat;
			display: inline-block;
			vertical-align: middle;
			@include border-radius(100px);
		}
		.pr-name{
			display: inline-block;
			vertical-align: middle;
			margin-left: 20px;
			line-height: 20px;
			.name{
				font-size: $font_14;
				font-weight: 500;
				color: $genric-color-w;
			}
			.view{
				font-size: $font_12;
				font-weight: 500;
				color: $genric-color-w;
			}
		}
	}
	.switch-host{
		padding:0  15px;
		position: relative;
		width: 100%;
		height: 60px;
		background-color: #fbdd66;
		line-height: 60px;
		.text{
			font-size: 14px;
			font-weight:500;
			color: $color-primary;
		}
		.switch-icon{
			display: inline-block;
			width: 40px;
			background: url(../img/common/common-sprite.svg);
			height: 25px;
			vertical-align: middle;
			background-position: -276px -550px;
		}
	}
	ul.list{
		background: #fff;
		padding: 10px 15px; 
		li{
			border-bottom: 1px solid #f2f2f2;
			a{
				padding: 20px 0;
				display:block;
				outline: none;
			}
			.icon{
				vertical-align: middle;
				display: inline-block;
			}
			.text{
				vertical-align: middle;
				display: inline-block;
				font-size: $font_15;
				font-weight: 300;
				text-align: left;
				color: #7b7b7b;
			}
		}
		li:last-child{
			border:none;
			.text{
				vertical-align: middle;
				display: inline-block;
			}
		}
	}
	.app-wrap{
		padding: 30px 15px;
		width: 100%;
		text-align: center;
		.heading{
			font-size: $font_15;
			font-weight: 500;
			text-align: center;
			color: $color-primary;
		}
		.dowload-image{
			margin-top: 30px;
			margin-bottom: 20px;
		}
	}
}
.profile-section{
    background: #F9F8F5;
     .header{

     	&.active .title{
			display:block;
		}
    	.title{
			font-size: 18px;
			font-weight: 300;
			line-height: 37px;
			display:none;
			text-align: center;
			color: #ffffff;

    	}
    }

}
.pro{
	& + .bottom-tabs-ul{
        display: none;
    }
}
.wrap-more{
    margin-top:10px
}
.varified-content{
	color: #858585;
	font-size: 13px;
}
.varified-content-green{
	color:$color-primary;
	font-size: 13px;
}
.varify-text{
	font-size: 12px;
	font-weight: 300;
	text-align: right;
	color: $color-primary;
	display: inline-block;
	padding-top:12px; 
		.linkP{
			border: none;
    		background: none;
			&:focus{
				outline: none !important;
			    background-color: transparent;
			    -webkit-appearance: none;
			    overflow: hidden;
			    border: none;
			    color:$color-primary;
			    font-size: 12.2px;
			    font-weight: 300;
			}
		}
	}  
	.profile-pic.default-pic{
     	background-size: 70%;
    }
    
    .profile-pic{
    	background-size:cover;
        background-color:#fde250;
        background-position: center;
        padding-top: 77%;
        position: relative;
        background-repeat: no-repeat;
    } 

	.profile-details{
		padding: 30px 15px 0 15px;
		background: $genric-color-w;
		position: relative;
		clear: both;
    	float: left;
    	width:100%;
		.height{
			height: 10px;
		}
		.p_container{
			position: absolute;
			top: -35px;
			left: 50%;
			@include translateX(-50%);
			@include border-radius(2px);
			background-color: $genric-color-w;
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
			padding: 11px 35px;
			width: 80%;
			text-align: center;
		}
		.profile-name{
			color: $color-dark-grey-2;
			font-size: $font_16;
			font-weight: 500;
		}
		.profile-duration{
			font-size: $font_13;
			margin-top: 5px;
			font-weight: 300;
			color: $color-dark-grey;
		}
		.p_host{
			width: 87%;
			margin: 30px auto;
			.name{
			font-size: $font_12;
			font-weight: 300;
			color: $color-dark-grey;
			}
			.number{
			font-size: $font_13;
			font-weight: 500;
			color: $color-dark-grey;
			}
			.wrap{
				display: inline-block;
			}
		}

		
		.pro-info{
			margin-top: 40px;
			float: left;
			padding-bottom: 5px;
			border-bottom: 1px solid #f2f2f2;
			width: 100%;
			.p_details{
				display: inline-block;
				text-align: left;
			}
		}
		.details{
			display: inline-block;
			vertical-align: top;
			margin-bottom: 25px;
			line-height: 18px;
			text-align: left;
			width: auto;
			.name{
				font-size: $font_14;
				font-weight: 300;
				color: $color-dark-grey;
				display: inline-block;
				vertical-align: text-top;
			}
			.text{
				font-size: 13px;
				font-weight: 500;
				width: 106px;
				color: $color-dark-grey;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@supports (-webkit-line-clamp: 2) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: initial;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				}
			}
		}
		.border-bottom{
				border-bottom: 1px solid #f2f2f2;;
				padding-bottom: 10px;
		}
		.social-details:first-child{
			padding-top: 20px;
		}
		.social-details{
			float: left;
			width: 100%;
			.name{
				
			}
			.text{
				font-size: 13px;
				font-weight: 500;
				color: $color-primary;
			}
			
		}
		
	}
	
.details{
	display: inline-block;
	vertical-align: top;
	margin-bottom: 25px;
	line-height: 18px;
	text-align: left;
	width: 86%;
	.name{
		font-size: $font_14;
		font-weight: 300;
		color: $color-dark-grey;
	}
	.text{
		font-size: 13px;
		font-weight: 500;
		color: $color-dark-grey;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		@supports (-webkit-line-clamp: 2) {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		}

	}
}
.wrap-more{
    padding: 20px 15px 0 15px;
    background: $genric-color-w;
    .heading{
        margin-bottom: 25px;
    }
    .more-about:last-child{
        border:none;
        //padding-bottom:100px;
    }

}
.heading{
	font-size: $font_15;
	font-weight: 500;
	color: $color-dark-grey;
}
.varificationEmail{
	font-size: 16px;
	font-weight: 500;
	color:  #858585;
	line-height: 1.5;
	text-align: center;
}
.edit{
	& + .bottom-tabs-ul{
	    display: none;
	}
}
.profile-edit-section{
    background: #F9F8F5;
    padding-bottom: 50px;
    .heading{
        margin-bottom: 0;
    }
    .floating-btn-box{
    	z-index:9;
    	.floating-btn{
        	padding:0 35px;
    	}
    }
   	
    .sticky-button {
      background-color: #00ba8c;
      position: fixed;
      bottom: 80px;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      -ms-border-radius: 40px;
      border-radius: 40px;
      overflow: hidden;
      padding: 2px;
      left: 50%;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      box-shadow: 0 2px 12px 0 rgba(114, 114, 114, 0.5); 
        a {
            float: left;
            height: 45px;
            padding: 13px 32px;
            background-color: #00ba8c;
            line-height: 18px;
            font-weight: 500;
            color: #fff;
            font-size: 14px; 
        }
    }
    
    .profile-pic.default-pic{
     	background-size: 70%;
    }

    .profile-pic{
    	background-size: cover;
        background-color:#fde250	;
        background-position: center;
        padding-top: 77%;
        position: relative;
    }
        .more-about{
            border-bottom: 1px solid #f2f2f2;
            float: left;
            width: 100%;
            padding-top: 25px;
        }
    .edit-details{
        background: #fff;
        padding: 0px 15px; 
        width:100%;
        float:left;
        clear:both;
        .heading{
            margin: 30px 0;

        }
        .more-about:first-child{
            padding-top: 30px;
        }
        .more-about{
            border-bottom: 1px solid #f2f2f2;
            float: left;
            width: 100%;
            padding-top: 25px;

		}
		.profile-icon{
			width: 20px;
			height: 20px;
			background-image: url(../img/common/common-sprite.svg);
			background-position: -127px -126px;
			background-clip: padding-box; 
			display: block;
			position:absolute;
			top: 15px;
    		left: 10px;
		}
        input[type=text] {
            width: 48%;
            box-sizing: border-box;
            font-size: 16px;
            color:  #858585;
            border-radius: 4px;
            height: 50px;
            background-color: #f9f8f5;
            border: solid 1px #e5e5e5 !important;
			padding: 0px 10px 0px 10px;
            &.profile{
                margin-right:10px; 
				padding: 0px 10px 0px 40px;
			}
        }

    }
    .header{
    	&.active .title{
			display:block;
		}
    	.title{
			font-size: 18px;
			font-weight: 300;
			line-height: 37px;
			display:none;
			text-align: center;
			color: #ffffff;

    	}
    }
   }

.outer-box{
	margin:72px 15px 0 15px;    
	.inner-box{
		background: $genric-color-w;
		margin-bottom: 10px;
		padding: 35px 15px;
		.list{
			li{
				padding: 15px 0;
				font-size: 14px;
				font-weight: 300;
				text-align: left;
				color: #a5a5a5;
				border-bottom: 1px solid #f2f2f2;
				a{
					font-size: 14px;
					font-weight: 300;
					text-align: left;
					color: #a5a5a5;
				}
			}
			li:last-child{
				border-bottom: none;
			}
		}
		.text{
			font-size: 13px;
			font-weight: 300;
			text-align: left;
			vertical-align: middle;
			display: inline-block;
			color: #7b7b7b;
		}
		.icon{
			vertical-align: middle;
			margin-right: 15px;
			width: 35px;
			height: 35px;               
		}
	}   
}
.setting{
	.outer-box{
		background: #F9F8F5;
		.inner-box{
			padding: 15px 15px 0 15px;  
			.heading{
				margin-bottom: 10px;
			}

		}
	}
	& + .bottom-tabs-ul{
		display: none;
	}
}

.Wallet{
	.header {
		.header-balance {
			@include headline($genric-color-w, $font_14);
			line-height:38px;
			display:none;
			&.active {
				display:block;
			}
		}
		&.isShadow {
			box-shadow:rgba(0,186,140,0.2) 0px 5px 2px;
		}
	}
	.info-window {
		padding-top:60px;
	}
	.wallet-image{
		margin-top: 60px;
		background: $color-primary;
		img{
			width: 100%;
		}
	}
	.wallet-ballance{
		background: $color-primary;
		padding: 20px 0;
		@include font-weight(medium);
		@include headline($genric-color-w, $font_18);
		text-align: center;
		margin-top:-1px;
	}
	.tab-menu{
		background: $genric-color-w;
		li{
			width: 50%;
			display: inline-block;
			text-align: center;
			padding:18px 0;
			font-weight: 500;
			border-bottom: 2px solid #f2f2f2;
			color: #adadad;
			font-size: 15px;
			outline:none;
			&.react-tabs__tab--selected{
				color: $color-primary;
				border-bottom: 2px solid $color-primary;
			}
		}

	}

	& + .bottom-tabs-ul{
		display: none;
	}   
}

.react-tabs{
		height: calc(100vh - 60px);
		margin-bottom: -60px;
		.Transaction{
			position:relative;
			&.react-tabs__tab-panel--selected {
				&.scrollAble {
					height:calc(100% - 60px);
					overflow-y:scroll;
					-webkit-overflow-scrolling: touch;
				}
				&.no-transaction{
					height: 150px;
				}
			}
			.background-color {
				padding:0 15px;
				background: $genric-color-w;
			}
			.default-text{
				position:absolute;
				top:50%;
				left:0;
				width:100%;
				text-align:center;
				@include translateY(-50%);
			}
			.row {
				border-bottom: 1px solid #f2f2f2;
				&:first-child {
					padding-top:15px;
				}
				&:last-child {
					border:none;
				}
			}
		.icon{
			vertical-align: top;
			margin-right:8px;
		}
		.text{
			vertical-align: middle;
			display: inline-block;
			font-size: $font_15;
			@include font-weight(medium);
			span{
				@include headline(#adadad,$font_12);
				display: block;
				@include font-weight(thin);
			}
			&.left-side {
				width:calc(100% - 35px);
			}
		}

		.wrap{
			position: relative;
			padding: 15px 0;
		}
	}
	.earn{
		&.react-tabs__tab-panel--selected {
			&.scrollAble {
				height:calc(100% - 60px);
				overflow-y:scroll;
				-webkit-overflow-scrolling: touch;
			}
		}
		.background-color {
			padding:0 15px;
			background: $genric-color-w;
		}
		.row {
			border-bottom: 1px solid #f2f2f2;
			&:first-child {
				padding-top:15px;
			}
			&:last-child {
				border:none;
			}
		}
		.wrap{
			position: relative;
			padding: 20px 0;
		}

		.text{
			font-size: 15px;
			color: $color-primary;
			display: inline-block;
			width: 60%;
			span{
				color: #858585;
				font-size: 13px;
				display: block;
			}
		}
		.icons{
			margin-top: 30px;
		}
		.icon{
			vertical-align: top;
			width: 75px;
			height: 75px;
			display: inline-block;
			background-size:cover !important;
		}

	}
}
// for wishlist
.wishlist-page {
	margin-bottom:0;
	.wishlist-content {
		margin:75px 15px 0 15px;
		.info-window{
			margin:0;
			.afterlogin{
				.imgb{
					padding:0 37px;
					margin-bottom:36px;
				}

			}
		}

	}
	.bottom-tabs-ul {
		display:none;
	}
	.trip{
		margin: 0;
	}
	.loader {
		height:calc(100vh - 60px);
		overflow:hidden;
		&.false {
			display:none;
		}
	}
}
.icon{
	vertical-align: middle;
	display: inline-block;
}



 ///  edit profile modal section  //////////////
.editProfile{
	width: 90%;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	button{
		width: 184px !important;
		transition:none;
	}
	button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

	.box{
		margin-top: 30px;
		label{
			border-bottom: 1px solid #f2f2f2;
		}
		label:last-child{
			border: none;
		}
	}
	.modal-body{
		padding: 25px 30px !important;
		.back-btn{
			background-color: transparent;
			background-image: url(../img/common/common-sprite.svg);
			width: 24px;
			height: 23px;
			position: absolute;
			right: 0px;
			top: -45px;
			border: none;
			border-radius: 0;
			background-position: -393px -344px;
		}
		.editprofile_form{
			position: relative;
			.error{
				width: 100%;
				position: absolute;
				top:85px;
				left: 50%;
				transform: translateX(-50%);
				&.textarea{
					top:140px;
				}
			}
		
		}
	}
	.verify-text1{
		font-size: 16px !important;
		font-weight: 500 !important;
		line-height: 1.5;
		color: #00ba8c !important;
	}
	.input-box{
			width: 100%;
			height: 44px;
			color: #858585;
			resize:none;
			margin-top: 30px !important;
			margin-bottom: 20px;
			padding: 10px 10px !important;
			background-color: #f9f8f5 !important;
			border: solid 1px #e7e7e7 !important;
			outline: none;
			transition: none !important;
		 }
	.texarea{
		width: 100%;
		height: 100px;
		color: #858585;
		resize:none;
		margin-top: 30px !important;
		margin-bottom: 20px;
		padding: 10px 10px !important;
		background-color: #f9f8f5 !important;
		border: solid 1px #e7e7e7 !important;
		outline: none !important;
	}
	/* The container */
	.container {
		display: block;
		position: relative;
		padding: 15px 0;
		cursor: pointer;
		text-align: left;
		font-size: 16px;
		width: 100%;
		font-weight: 500;
		color: #858585;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		&:after{
			display: none;
		}
	}

	/* Hide the browser's default radio button */
	.container input {
		position: absolute;
		opacity: 0;
		text-align: left;
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 10px;
		right: 0;
		height: 25px;
		width: 25px;
		background-color: #fff;
		border-radius: 50%;
	}

	/* On mouse-over, add a grey background color */
	.container:hover input ~ .checkmark {
		background-color: #fff;
	}

	/* When the radio button is checked, add a blue background */
	.container input:checked ~ .checkmark {
		background-image: url(../img/common/common-sprite.svg);
		background-position: -79px -790px;
		background-repeat: no-repeat; 

	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	.container input:checked ~ .checkmark:after {
		display: block;
	}
}

///date of birth modal
.datepicker-modal{
	font-family: "MuseoSans" !important;
	background-color: rgba(0, 0, 0, .85) !important;
	top:0;
	bottom: auto !important;
	.datepicker{
		width: 90%;
		left: auto!important;
		border-radius: 4px;
		top:50%;
		bottom: auto!important;
		transform:translateY(-50%);
		-webkit-transform:translateY(-50%);
		-moz-transform:translateY(-50%);
		.datepicker-viewport{
			height: 145px !important;
			&:after{
				content: '';
				 background-image: -webkit-gradient(linear,left top, left bottom,from(#fff),color-stop(52%, rgba(245, 245, 245, 0)),color-stop(48%, rgba(245, 245, 245, 0)),to(#fff)) !important;
				
			}
		}
		&.android{
			background: #fff;
		}
		.datepicker-header{
			font-size: 16px;
			font-weight: 500;
			line-height: 1.5;
			text-align: center;
			color: #00ba8c!important;
			border-bottom: none!important;
			padding: 20px 0;

		}
		
		.datepicker-scroll{
			li{
				font-size: 16px;
				font-weight: 500!important;
				color: #858585!important;
			  
			}
		}
		.datepicker-wheel{
			border-top: 1px solid #f2f2f2 !important;
			border-bottom: 1px solid #f2f2f2!important;
			height: 40px;

		} 
		.datepicker-col-1{
			margin: 0!important;
		}
 
	}
	.datepicker-navbar{
		.datepicker-navbar-btn{
			width: 186px;
			height: 54px;
			margin: 30px auto;
			font-weight:700;
			font-size: 17px;
			line-height: 54px;
			color: #fff !important;
			border-radius: 100px;
			background-color: #00ba8c;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
			-webkit-box-flex: none!important;
			-ms-flex: none!important;
			flex: none!important;
		}
		.datepicker-navbar-btn:last-child{
		   position: absolute;
			top: -80px;
			right: 0;
			content: '';
			background-color: transparent;
			background-image: url(../img/common/common-sprite.svg);
			width: 20px;
			height: 19px;
			position: absolute;
			border: none;
			border-radius: 0;
			background-position: -397px -344px;
			border-left: none!important;
		}
		border-top:none !important;
	}
}

input, textarea {
	-webkit-appearance: none;
}
.currency{
	.modal-body{
		.list{
			padding: 0px 15px;
				list-style: none;
				li{
					list-style: none;
					padding: 10px 0;
					font-size: 16px;
					border-bottom: 1px solid #f2f2f2;
				}
				/* The container */
			.container {
				display: block;
				position: relative;
				padding: 10px 0;
				cursor: pointer;
				text-align: left;
				font-size: 14px;
				width: 100%;
				border-bottom: 1px solid #f2f2f2;
				font-weight: 500;
				color: #858585;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				&:after{
					display: none;
				}
			}
					 /* Hide the browser's default radio button */
			.container input {
				position: absolute;
				opacity: 0;
				text-align: left;
			}

			/* Create a custom radio button */
			.checkmark {
				position: absolute;
				top: 10px;
				right: 0;
				height: 25px;
				width: 25px;
				background-color: #fff;
				border-radius: 50%;
			}

			/* On mouse-over, add a grey background color */
			.container:hover input ~ .checkmark {
				background-color: #fff;
			}

			/* When the radio button is checked, add a blue background */
			.container input:checked ~ .checkmark {
				background-image: url(../img/common/common-sprite.svg);
				background-position: -79px -790px;
				background-repeat: no-repeat; 

			}

			/* Create the indicator (the dot/circle - hidden when not checked) */
			.checkmark:after {
				content: "";
				position: absolute;
				display: none;
			}

			/* Show the indicator (dot/circle) when checked */
			.container input:checked ~ .checkmark:after {
				display: block;
			}
		}
		
	}
}
.property-listing{
	margin-bottom:40px;
	clear:both;
	.heading-section{
		h2.heading{
			font-size:16px;
		}
	}
	.description{
		.dot{
			display: inline-block;
		    vertical-align: middle;
		    /* position: relative; */
		    height: 25px;
		}
		span.location{
			margin-top:0 !important;
		}
		.rating{
			width: 32px !important;
			height: 32px !important;
			background-color: $color-green;
			color:$genric-color-w;
			font-size:15px !important;
			@include border-radius(50%);
			@include font-weight(bold);
			margin-top:12px !important;
			line-height:32px !important;
			text-align:center;
		}
	}
}
.editProfile.changepassword{
	input[type=password]{
		margin: 0 0 0 0;
		margin-top: 20px !important;
	}
	input[type=email]{
		margin-bottom:0px;
	}
	input[type=password]:first-child{
		margin-top:30px!important;
	}
	.link{
		margin-top: 30px;
		width: 200px!important;
	}

}
.editProfile.deactivate{
	.verify-text1{
		font-size: 17px;
		font-weight: 500;
		text-align:center;
		color: #7b7b7b !important;
	}
	.link{
		margin-top:0;
	}
	input[type="password"]{
		height:55px;
		margin-bottom:0;
	}
	.response-msg{
		margin-top: 0px;
		margin-bottom:0px;
	}
}
.response-msg{
	margin-top: 20px;
	&.true{
		display: block;
	}
	&.false{
		display: none;
	}
}