// common classes
.text-left{
  text-align:left;
}
.text-right{
  text-align:right;
}
.text-center{
  text-align:center;
}
.pull-left{
  float:left;
}
.pull-right{
  float:right;
}
.position-relative {
  position:relative;
}
.disable-btn {
  opacity:0.5;
  pointer-events:none;
}
button{outline:none}
.see-all {
  @include headline(#34b694,$font_12);
}

input::-webkit-input-placeholder {
color:#cacaca !important;
}
textarea::-webkit-input-placeholder {
  color:#cacaca !important;
}
textarea::-moz-placeholder {
  color:#cacaca !important;
}
textarea:-moz-placeholder {
  color:#cacaca !important;
}
input:-moz-placeholder { /* Firefox 18- */
  color: #cacaca !important;  
}

input::-moz-placeholder {  /* Firefox 19+ */
color:#cacaca !important;  
}

input:-ms-input-placeholder {  
color:#cacaca !important;  
}

.overlay-modal {
  background-color:rgba(255, 255, 255, 0.75);
  position:fixed;
  outline: none;
  top:-1px;
  right:0;
  bottom:0;
  left:0;
  z-index:999;
  .content-modal {
	background-color:#fff;
	position:absolute;
	top:50%;
	left:50%;
	outline: none;
	transform:translate(-50%, -50%);
	&.full-screen-modal {
	  width:100%;
	  height:100%;
	}
	.modal-header {
	  position:relative;
	  h2 {
		text-align: center;
		@include headline($color-dark-grey-2,$font_18);
		padding:21px 0;
		margin:0;
		line-height:18px;
		@include font-weight(medium);
	  }
	  .transparent-gredient {
			width:100%;
			height:30px;
			position:absolute;
			bottom:-10px;
			left:0;
			background-image:url(../img/filter-gradient.png);
			background-repeat:repeat-x;
			z-index:9;
	  }
	  .header-btn {
			background-color:transparent;
			background-image:url($sprite-image);
			width:52px;
			height:52px;
			position:absolute;
			left:0;
			top:0;
			border:none;
			@include border-radius(0);
			z-index: 99;
		&.close-btn {
			background-position: -107px -216px;
			transform: scale(1.3);
			-webkit-transform: scale(1.3);
			-moz-transform: scale(1.3);
		}
		&.back-btn {
			background-position:-807px -279px;
			width:60px;
			height:60px;
		  &.white-icon {
		  	background-position:-276px -214px;
		  }
		}
	  }
	  span, button {
		background:transparent;
		padding:21px 15px;
		@include headline($color-dark-grey-2,$font_14);
		position:absolute;
		top:0;
		line-height:18px;
		border:none;
		&.reset-btn {
		  right:0;
		}
		&.disabled {
			opacity: 0.5;
		} 
		&.close-btn {
		  left:0;
		}
	  }
	  &.green-header {
		background: $color-primary;
		h2 {
		  color:$genric-color-w;
		}
		.header-btn {
		  &.back-btn {
				background-position:-52px -377px;
				transform: scale(1.3);
				-webkit-transform: scale(1.3);
				-moz-transform: scale(1.3);
				width:52px;
				height:52px;
		  }
		  &.close-btn {
			background-position:-378px -326px;
		  }
		}
		}
		&.active{
			background-color: $color-primary;
			color: #fff;
			h2{
				color: #fff;
			}
		}
	}
	.modal-body {
	  width:100%;
	  height: calc(100% - 60px) !important;
	  overflow-y: scroll;
	  -webkit-overflow-scrolling: touch;
	}
	.touchableOff{
		pointer-events:none !important;
	}
  }
}

.header{
  position: fixed;
  padding: 12px 15px;
  height: 62px;
  top:0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 9;
  width: 100%;
  transition:top 0.5s ease;
  transition: background-color 0.5s ease;
  span{
		display: inline-block;
  }
	.back-icon{
		float:left;
		width: 50px;
		height: 65px;
		background: url(../img/common/common-sprite.svg);
		background-position: -276px -211px;
		padding: 15px 10px 15px 15px;
		margin: -15px -10px -15px -15px
	}
	.back-btn-grey{
		float:left;
		width: 50px;
		height: 65px;
		background: url(../img/common/common-sprite.svg);
		background-position: -807px -275px;
		padding: 15px 10px 15px 15px;
		margin: -15px -10px -15px -15px

	}
	.cross-icon{
		width:48px;
		height:48px;
		background: url(../img/common/common-sprite.svg);
		background-position: -117px 631px;
		transform: scale(1.3);
    margin: -5px 0 0 -8px;
	}
	.cross-icon-white{
		width: 25px;
		height: 22px;
		background: url(../img/common/common-sprite.svg);
		background-position: -397px -344px;
	}

  .profileEdit-icon{
		width: 25px;
		height: 30px;
		background: url(../img/profile-edit.png);
		background-size: 24px;
		background-position: 0;
    background-repeat: no-repeat;
  }
  .camera-icon{
		width: 25px;
		height: 30px;
		background: url(../img/camera.png);
		background-size: 24px;
		text-indent:-99999px;
		background-position: 0;
    background-repeat: no-repeat;
  }
  .middle-text{
		display: inline-block;
	  vertical-align: top;
	  /* text-align: center; */
	  /* width: 100%; */
	  transform: translateX(-50%);
	  left: 50%;
	  position: absolute;
	  font-size: 16px;
	  font-weight: 500;
	  text-align: center;
	  color: $genric-color-w;
	  line-height:38px;
	}
	.transparent-gredient {
		width:100%;
		height:30px;
		position:absolute;
		bottom:-10px;
		left:0;
		background-image:url(../img/filter-gradient.png);
		background-repeat:repeat-x;
		z-index:9;
	}

  &.active{
		background: $color-primary;
  }
	&.white{
		background: $genric-color-w;
		.middle-text {
			color: $color-dark-grey-2;
		}
	}
	&.awaiting{
		background-color: #d8b215;
	}
	&.expired{
			background-color:#c4c4c4; 
	}
	&.canceled{
			background-color: #f2a419;
	}
	&.not-avalable{
			background-color: #f26565;
	}
	&.approved{
			background-color: #00ba8c;
	}
	&.rejected{
			background-color:  #f26565;
	}
}
.icon{
	  width: 27px;
	  height: 25px;
	  display: inline-block;
	  vertical-align: text-top;
	  margin-right: 10px;
	  background: url('../img/common/common-sprite.svg');

	  &.wishlist{
		background-position: -278px -600px;
	  }
	  &.wallet{
		background-position: -282px -650px;
	  }
	  &.refer{
	   background-position: -285px -695px;
	 }
	  &.gender{
		background-position: -15px -523px;
	  }
	  &.profession{
		background-position: -15px -523px;
	  }
	  &.email{
		background-position: -19px -696px;
	  }
	  &.phone-number{
		background-position: -25px -742px;
	  }
	  &.facebook{
		background-position: -27px -787px;
	  }
	  &.payout{
		background-position: -335px -660px;
	  }
	  &.listing{
		background-position: -335px -707px;
	  }
	  &.add-pro{
		  background-position: -337px -756px;
	  }
	  &.work{
		background-position: -286px -784px;
	  }
	  &.help{
		background-position: -286px -826px;
	  }
	  &.setting{
		background-position: -331px -514px;
	  }
	  &.birth{
		background-position: -15px -523px;
	  }
	  &.marital{
		background-position: -17px -567px;
	  }
	  &.gender{
		background-position: -21px -610px;
	  }
	  &.profession{
		background-position: -18px -652px;
	  }
	  &.email{
		background-position: -19px -696px;
	  }
	  &.phone-number{
		background-position: -25px -742px;
	  }
	  &.facebook{
		background-position: -27px -787px;
	  }
	  &.language{
		background-position: -72px -526px;
	  }
	  &.tidbits{
		background-position: -76px -629px;
	  }
	  &.place{
		background-position: -76px -577px;
	  }
	  &.chat{
		background-position: -520px -283px;
	  }
	  &.email-icon{
		background-position: -564px -102px;
	  }
	  &.phone{
		background-position: -566px -219px;
	  }
	  &.paid{
		background-position: -477px -299px;
	  }
	  &.unpaid{
		background-position: -478px -345px;
	  }
	  &.referral-first{
		background-position: -442px -417px;
	  }
	  &.earn-by-review{
		background-position: -522px -360px;
	  }


	}
  .icons{
		width: 25px;
		height: 25px;
		display: inline-block;
		vertical-align:middle;
		text-align: right;
		background: url('../img/common/common-sprite.svg');
		
		&.varify{
		  background-position: -78px 183px;
		  height: 30px;
		   
		}
		&.arrow{
		  background-position: -240px -833px;
		  height: 18px;
		  width: 15px;
		  margin-top: 13px;
		}
		&.v-arrow{
		  background-position: -240px -833px;
		  height: 18px;
		  width: 15px;
		  margin-top: 0px;
		}
		&.p-arrow{
		  background-position: -238px -804px;
		  height: 15px;
		  width: 15px;
		  position: absolute;
		  right: 15px;
		  top: 50%;
		  @include translateY(-50%);
			
		}
		&.p-arrow-green{
			background-position: -240px -833px;
			height: 15px;
			width: 15px;
			position: absolute;
			right: 15px;
			top: 50%;
			@include translateY(-50%);
		}
		&.p-arrow-gray{
		  background-position: -273px -513px;

		}
		&.arrow-dark-gray{
			background-position: -236px -453px;
			height: 25px;
			width: 15px;
		}
		
	  }
.apple{
  width: 135px;
  height: 40px;
  display: inline-block;
  margin-right: 20px;
  background-image: url(../img/app-store.png);
  background-size: cover;
}
.google{
  width: 135px;
  height: 40px;
  display: inline-block;
  background-image: url(../img/google-store.png);
  background-size: cover;
}
.overlay-modal .content-modal.download-app .modal-body{
	height: 100% !important;
}
// app dowload
.download-app {
  background: $color-primary;
  .modal-body{
	background: $color-primary;
	height: 100% !important;
	.back-btn{
		background-color: transparent;
		background-image: url(../img/common/common-sprite.svg);
		width: 30px;
		height: 30px;
		position: absolute;
		left: 15px;
		top: 16px;
		border: none;
		border-radius: 0;
		transform: scale(1.3);
		-webkit-transform: scale(1.3);
		-moz-transform: scale(1.3);
		background-position: -67px -392px;
	}
	.top-text{
	  font-size: 21px;
	  font-weight: 300;
	  text-align: center;
	  color: $genric-color-w;
	  margin-top: 98px;
	}
	.middle-text{
	  font-size: 15px;
	  font-weight:300;
	  text-align: center;
	  margin-top: 40px;
	  color: $genric-color-w;
	}
	.bottom-text{
	  font-size: 14px;
	  font-weight: 300;
	  text-align: center;
	  margin-top: 73px;
	  color:$genric-color-w;
	  a.c-no{
		font-size: 17px;
		font-weight: 300;
		text-align: center;
		color: #fadc73;
	  }
	}
	.dowload-image{
	  margin-top: 68px;
	  text-align: center;
	  width: 100%;
	}
  }
}


// floating btn
.floating-btn-box {
	position:fixed;
	bottom:0;
	left:50%;
	width:auto;
	transform:translate3d(-50%,200%,0);
	-webkit-transform:translate3d(-50%,200%,0);
	-moz-transform:translate3d(-50%,200%,0);
	@include transition(all 0.3s linear);
	opacity:0;
	z-index:9999999;
	&.active {
		transform:translate3d(-50%,-35px,0);
		-webkit-transform:translate3d(-50%,-35px,0);
		-moz-transform:translate3d(-50%,-35px,0);
		opacity:1;
	}
	.floating-btn {
		background-color: #00ba8c;
		height: 54px;
		line-height: 54px;
		text-align: center;
		@include headline($genric-color-w,$font_17);
		@include font-weight(bold);
		@include border-radius(27px);
		display: inline-block;
		margin: 0 auto;
		padding: 0 83px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	}
}
.overlay-popup{
  background-color: rgba(0, 0, 0, 0.9);
  .content-modal.resendOTP{
	width: 85%;
	height:auto;
	border-radius: 4px;
	outline: none;
	background-color: #ffffff;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	 .modal-body{
		width: 100% !important;
		padding: 30px;
		height: 100% !important;
		.back-btn{
            background-color: transparent;
            background-image: url(../img/common/common-sprite.svg);
            width: 24px;
            height: 23px;
            position: absolute;
            right: 0px;
            top: -45px;
            border: none;
            border-radius: 0;
            background-position: -393px -344px;
        }
	
	.verify-text{
	  font-size: 16px;
	  font-weight: 300;
	  line-height: 1.5;
	  text-align: center;
	  color: #858585;
	  margin-bottom: 10px;
	}
  } 
  }

  .link{
	border: none;
	color: white;
	height: 54px;
	border-radius: 100px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 17px;
	width: 100%;
	margin-top:30px;
	line-height: 54px;
	cursor: pointer;
	span{
		font-size: 17px;
	}
	&.green{
	  background-color: $color-primary !important;
	  box-shadow:rgba(0,0,0,0.2) 0px 0px 6px;
	}
	&.green-outline{
	  background-color: transparent !important;
	  color: $color-primary !important;
	  border:1px solid $color-primary !important;
	  box-shadow:rgba(0,0,0,0.2) 0px 0px 6px;
	}
  }
}
.error{
	  color:#f26565;
	  font-size:13px;
	  font-weight: 500;
	  text-align: left;
}
.success{
	  color:$color-primary;
	  font-weight: 500;
	  text-align: left;
	  font-size:13px;
}
input {
	-webkit-appearance: none;
	font-size: 14px;
  	font-weight: 500;
}
.loading{
	  &.active{
			background-image: url(../img/loading.gif);
			background-color: rgba(255,255,255,0.8);
			position: fixed;
			background-repeat: no-repeat;
			background-position: center; 
			height: 100%;
			width: 100%;
			top:0;
			left:0;
			z-index: 9999999;
	  }
}
.ReactModal__Body--open{
	overflow: hidden!important;
	position:fixed;
	height:100vh !important;
}

.overlay-modal .content-modal.cancel-modal{
	.heading{
		margin-top:55px;
		margin-bottom: 32px; 
		font-size: 16px;
		font-weight: 500;
		text-align: center;
		color: #7b7b7b;
		padding: 0 50px;
	}
	.responseAPI.true{
		z-index: 10;
		.success{
			color: #7b7b7b;
			font-size:12px;
			span{
    		position:relative;
    		&:before{
    		    content: "";
			    width: 27px;
			    height: 26px;
			    position: absolute;
			    left: -35px;
			    background-repeat: no-repeat;
			    top: -6px;
				background-image:url('../img/error-img.png');
    		}
    	}
		}
	}
	.footer-wrap{
		margin:0 20px;
		.textarea-wrap{
			position:relative;
			margin-bottom:10px;
			.word-limit{
				text-align: right;
			    font-size: 13px;
			    font-weight: 500;
			    position:absolute;
			    color: #00ba8c;
			    right: 10px;
    			bottom: 7px;
			}
		}
		.textarea{
			padding:12px;
			width:100%;
			resize:none;
			border:1px solid #e5e5e5 !important;
			border-radius:3px;
			background:#f9f8f5;
			font-size:14px;
			color:$color-dark-grey;
			height:120px;
		}
		.button{
			background:$color-primary;
			color:#fff;
			padding:20px 40px;
			outline:none;
			line-height:10px;
			&.bottom{
				position: absolute;
			    bottom: 30px;
			    text-align: center;
			    left: 50%;
			    transform: translateX(-50%);
			}
		}
		.true{
			display:block;
		}
		.false{
			display:none;
		}
	}
	
	.list{
		padding:0 15px;
	}
	.bullet {
	    display: block;
	    position: relative;
	    padding-left: 30px;
	    margin-bottom: 25px;
	    cursor: pointer;
	    font-size: 15px;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	}

	/* Hide the browser's default radio button */
	.bullet input {
	    position: absolute;
	    opacity: 0;
	    cursor: pointer;
	}

	/* Create a custom radio button */
	.checkmark {
	    position: absolute;
	    top: 0px;
	    left: 0;
	    height: 16px;
	    width: 16px;
	    background-color: #fff;
	    border-radius: 50%;
	    border:1px solid #b7b7b7;
	}

	/* On mouse-over, add a grey background color */
	.bullet:hover input ~ .checkmark {
	    background-color: #fff;
	}

	/* When the radio button is checked, add a blue background */
	.bullet input:checked ~ .checkmark {
	    background-color: #fff;
	    border:1px solid  #00ba8c;
	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
	    content: "";
	    position: absolute;
	    display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	.bullet input:checked ~ .checkmark:after {
	    display: block;
	}

	/* Style the indicator (dot/circle) */
	.bullet .checkmark:after {
	 	top: 3px;
		left: 3px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: #00ba8c;
	}
}
.overlay-modal .content-modal.cancel-modal{
	.modal-body{
		height:100%!important;
	}
}
.overlay-modal .content-modal.referearn{
	background: #fff;
  .modal-body{
	background:#fff;
	height: 100% !important;
	.modal-header{
		background-color: $color-primary;
		.back-btn {
			top:0px;
		}
		h2{
			font-size:17px;
			color:#fff;
			font-weight:300;
		}
	}
	// .back-btn{
	// 	background-color: transparent;
	// 	background-image: url(../img/common/common-sprite.svg);
	// 	width: 17px;
	// 	height: 16px;
	// 	position: absolute;
	// 	left: 15px;
	// 	top: 16px;
	// 	border: none;
	// 	border-radius: 0;
	// 	background-position: -73px -398px;
	// }
	.top-text{
		@include headline($color-primary, $font_18);
		@include font-weight(bold);
		text-align: center;
		margin-top: 18px;
		padding: 0px 18px;
		line-height:26px;
	}
	.img{
	  	width: 100%;
	    padding-top: 55%;
	    background-size:contain;
	    background-repeat: no-repeat;
	    border-radius: inherit;
	  }
	
	.bottom-text{
		@include headline($color-primary, $font_14);
		@include font-weight(medium);
		text-align: center;
		padding: 14px 0;
		width:200px;
		margin:28px auto 31px;
		border-width:1.5px;  
		border-style:dashed;
		color:rgb(156, 156, 156);
		span{
			@include headline(#9c9c9c, $font_17);
			text-align: center;
		}

	}
	.footer{
		@include headline($color-dark-grey, $font_14);
		@include font-weight(medium);
		text-align: center;
		margin-top: 10px;
		padding:0 18px;
		line-height:24px;
	}
	.socialIcon{
		width: 100%;
		ul{
			margin: 0 15px;
			list-style: none;
			li{
				display: inline-block;
				width: 55px;
				height: 55px;
				outline:none;
				background-repeat: no-repeat;
				outline:none;
				margin-right: 8%;
				display: inline-block;
				border-radius: 50%;

				div{
					width: 55px;
					height: 55px;
					outline:none;
					list-style: none;
					list-style-type:none;
					background-image: url(../img/common.svg);
					
					&.whatsapp{
						background-position: center;
						background-repeat: no-repeat;
						background-position: -22px -682px;
					}
					&.fb{
						background-position: center;
						background-repeat: no-repeat;
						background-position: -31px -524px;
					}
					&.msg{
						background-position: center;
						background-repeat: no-repeat;
						background-position: -31px -603px;
					}
					&.more{
						background-position: center;
						background-repeat: no-repeat;
						background-position: -31px -847px;
						
					}
				}
				
				
			}
			li:last-child{
				margin-right: 0;
			}
		}
	}
	.terms{
		@include headline($color-dark-grey, $font_12);
		@include font-weight(medium);
		text-align:center;
		margin-top:30px;
		display:block;
		width:100%;
	}
  }
}

.termconditon{
	padding-bottom: 42px;
	&.content-modal{
		.modal-body{
			height:calc(100% - 10px) !important;
			padding-top:20px; 
		}
	}
	.conditions{
		.heading{
			margin-top:25px;
			margin-bottom: 10px; 
			font-size: 16px;
			font-weight: 500;
			text-align: left;
			color: $color-primary;
			
		}
		.text{
			 font-size:14px;
			 line-height: 20px;
			 font-family: "MuseoSans" !important;
			ul{
				margin-left: 20px;
				li{
					list-style:disc;
				}
			}
		}
	}
	
}
.tags-list{
	li, .tag{
		&.family-friendly{
			background-color: #ffef8c;
			color: #b7ac27;
			i{
				background-position: -12px -159px;
				
			}
		}
		&.scenic{
			background-color: #ffef8c;
			color: #b7ac27;
			i{
				background-position: -10px -12px;
				width: 30px!important;
			}
		}
		
		&.value-for-money{
			background-color: #ffef8c;
			color: #b7ac27;
			i{
				background-position: -13px -87px;
			}
		}
		&.city-centre{
			background-color: #ffef8c;
			color: #b7ac27;
			i{
				background-position: -12px -275px;
			}
		}
		&.luxurious{
			background-color: #ffef8c;
			color: #b7ac27;
			i{
				background-position: -11px -432px;
			}
		}
		&.business-corporate{
			background-color: #ffef8c;
			color: #b7ac27;
			i{
				background-position: -12px -200px;
			}
		}
		
		&.casino-nearby{
			background-color: #bce9d1;
			color: #35b693;
			i{
				background-position: -10px -49px;
			}
		}
		&.easy-accessibility{
			background-color: #ffef8c;
			color:#b7ac27;
			i{
				background-position: -13px -390px;
			}
		}
		&.lake-facing{
			background-color: #bce9d1;
			color:#35b693;
			i{
				background-position: -60px -12px;
			}
		}
		&.romantic{
			background-color: #ffef8c;
			color:#b7ac27;
			i{
				background-position: -12px -49px;
			}
		}
		&.adventure{
			background-color: #ffef8c;
			color:#b7ac27;
			i{
				background-position: -13px -126px;
			}
		}
		&.offbeat{
			background-color: #ffef8c;
			color:#b7ac27;
			i{
				background-position: -12px -237px;
			}
		}
		
		&.peaceful{
			background-color: #ffef8c;
			color:#b7ac27;
			i{
				background-position: -11px -314px;
			}
		}
		&.outskirts{
			background-color: #ffef8c;
			color:#b7ac27;
			i{
			    background-position: -13px -354px;
			}
		}
		&.beach-facing,&.beachside{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -60px -50px;
			}
		}
		&.mountain-view,&.velley-view{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -56px -90px;
				width: 30px!important;
			}
		}
		&.pool-facing,&.shared-pool,&.private-pool{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -60px -132px;
			}
		}
		&.solo-travellers,&.backpackers{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -60px -168px;
			}
		}
		&.mall-nearby{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -60px -204px;
			}
		}
		&.female-friendly{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -60px -246px;
			}
		}
		&.market-nearby{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -58px -285px;
			}
		}
		&.cages-nearby,&.resturents-nearby,&.near-eateries, &.all-meals{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -57px -324px;
			}
		}
		&.pubs-nearby,&.clubs-nearby,&.nightclubs-nearby,&.near-pubs-bars{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -57px -362px;
			}
		}
		&.casino-nearby{
			background-color: #bce9d1;
			color:#00ac8f;
			i{
				background-position: -59px -401px;
			}
		}
		&.forest-retreat{
			background-color: #bce9d1;
			color:#35b693;
			i{
				background-position: -60px -437px;
			}
		}
		&.spiritual-retreat{
			background-color: #bce9d1;
			color:#35b693;
			i{
				background-position: -60px -476px;
			}
		}
		&.top-booked{
			background-color: #bce9d1;
			color:#35b693;
			i{
				background-position: -109px -11px;
			}
		}
		&.booked-today{
			background-color: #bce9d1;
			color:#35b693;
			i{
				background-position: -109px -11px;
			}
		}
		&.best-rated-host{
			background-color: #bce9d1;
			color:#35b693;
			i{
				background-position: -110px -88px;
			}
		}
		&.steal-deal{
			background-color: #bce9d1;
			color:#35b693;
			i{
				background-position: -110px -128px;
			}
		}
		&.best-reviewed{
			background-color: #bce9d1;
			color:#35b693;
			i{
				background-position: -110px -164px;
			}
		}
		
	}
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.rating-1{
	background-color:#f0b04c!important;
}
.rating-2{
	background-color:#eecd59!important;
}
.rating-3{
	background-color:#b0ca70!important;
}
.rating-4{
	background-color:#75c96f!important;
}
.rating-5{
	background-color:#37aa4f!important;
}
.error-404{
	.info-window{
		.window-container{
			background-color:#f9f8f5;
			.txt{
				margin-bottom:30px;
			}
			.txtb{
				margin-bottom:10px;

			}
			.btn{
				background-color: transparent;
			}
		}
	}
}
.info-window{
	&.afterlogin{
		.window-container{
			padding: 35px 10px 35px;
			.imgb{
				padding: 0px 37px;
				
			}
			.txtb{
				margin-bottom: 20px;
				p {
				font-size: $font_16;
				}
			}
		}
	}
}
.active-request{
	margin: 15px;
	position: relative;
		.active-container {
		padding: 40px 10px 0px;
		border-radius: 4px;
		width: 100%;
		text-align: center;
		.imgb{
			width: 100%;
			margin-bottom: 30px;
			padding: 0px 62px;
				.img{
					width: 100%;
					padding-top:100%;
					background-size:cover; 
					background-color:#f2f2f2; 
					border-radius: 50%;
				}
		}
		.txtb{
			margin-bottom: 20px;
			padding: 0 10px;
			p {
				font-size: $font_16;
			}
		}

	
	}
}
.info-window{
	margin: 15px;
	position: relative;
	.window-container {
		padding: 35px 10px 35px;
		border-radius: 4px;
		background: $genric-color-w;
		width: 100%;
		text-align: center;
		.imgb{
			width: 100%;
			margin-bottom: 30px;
			padding: 0px 62px;
				.img{
					width: 100%;
					padding-top:100%;
					background-size:cover; 
					background-color:#f2f2f2; 
					border-radius: 50%;
					&.error{
						background-color: transparent;
					    border-radius: 0;
					    background-size: contain;
					    background-position: 30px 0px;
					    background-repeat: no-repeat;
					}
				}
		}
		.txtb{
			margin-bottom: 20px;
			padding: 0 10px;
			p {
				font-size: $font_16;
			}
		}
		.retry{
			position:relative;
			font-size: 17px;
			font-weight: 500;
			height:25px;
			width:100px;
			color: #00ba8c;
			border:none;
			outline:none;
			background: none;
			&:before{
				content:'';
				background-image:url(../img/common.svg);
				position:absolute;
				width:30px;
				height:25px;
				top:0;
				left:0;
				background-position: -139px -265px;
			}
		}
		.btn{
			width:80%;
			margin: 0 auto;
			height: 55px;
			@include font-weight(medium);
			line-height: 50px;
			border-radius: 100px;
			@include headline($genric-color-w,$font_17);
			outline:none;
			color: #00ba8c;
			background-color: #ffffff;
  			border: solid 1.5px #00ba8c;
			&:hover {
				box-shadow:none;
			}
		} 
	}
}
.overlay-modal {
	.content-modal.recently-viewed{
		.modal-header{
			position:absolute;
			width:100%;
			z-index:999;
		}
	}
}
.explore-collection{
	padding:10px 0 35px;
	.heading{
		font-size: 20px;
		font-weight: 500;
		color: #00ba8c;
		margin-bottom:20px;
	}
	.collection-btn{
			width: 222px;
		    height: 50px;
		    border-radius: 100px;
		    background-color: #00ba8c;
		    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
		    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
		    line-height: 50px;
		    text-align: center;
		    margin:0 auto;
		    color:#fff;
		    font-size: 16px;
		}
	.collection-inner-section {
		margin-bottom: 20px;

		.swiper-wrapper{
			margin-bottom:20px;
		}
		.mask{
			border-radius: 2px;
		    background-color: rgba(0, 0, 0, 0.4);
		    background-size: cover;
		    position: relative;
		    .innermask{
		    	position:absolute;
		    	width:100%;
		    	height:100%;
		    	top:0;
		    	left:0;
		    	text-align:center;
		    	background-color: rgba(0, 0, 0, 0.4);
		    	.text{
		    		position:absolute;
		    		top:50%;
		    		left:50%;
		    		font-size: 20px;
		    		padding:0 15px;
		    		width: 100%;
  					font-weight: 500;
		    		text-align:center;
		    		color:#fff;
		    		transform:translate(-50%,-50%);
		    	}
		    }
		}
		.collection-item-tile {
			&.see-all-tile {
				height: calc(100% + 5px) !important;
				background: #7fdbc5;
				@include headline($genric-color-w, $font-18);
				@include font-weight(thin);
				span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}
			}

			.imgb {
				background-color: $color-light-grey-2;
				background-size: cover;
				background-position: top center;
				width: 100%;
				padding-top:67%;
				background-image:url(../img/search_placeholder.jpg);
				@include border-radius(2px);
				.wishlist-icon {
					background-image: url($sprite-image);
					position: absolute;
					width: 30px;
					height: 27px;
					top: 15px;
					right: 15px;
					background-position:-121px -12px;
					&.active {
						background-position:-67px -458px;
					}
				}
			}

			.description {
				background-color: $genric-color-w;
				padding:15px 15px 20px 15px;

				.row {
					margin:0;
				}

				h3 {
					@include headline($color-dark-grey, $font-16);
					@include font-weight(medium);
					margin: 0px 0px 5px;
					.dot-separation{
						position: relative;
						top: -4px;
						display: inline-block;
						margin: 0 5px;
						width: 2px;
						height: 2px;
						background: $color-dark-grey;
					}
				}

				.location {
					@include headline($color-dark-grey, $font-16);
					@include font-weight(thin);
					line-height:18px;
					text-overflow: ellipsis;
					overflow: hidden;
					display: block;
					white-space: nowrap;
				}

				.price {
					@include headline(#838383, $font-20);
					margin-top: 7px;
					display: block;
					line-height:23px;
				}
				
				.rating {
					width: 32px;
					height: 32px;
					background-color: $color-green;
					@include headline($genric-color-w,$font-15);
					@include border-radius(50%);
					@include font-weight(bold);
					margin-top:0px;
					line-height:32px;
					text-align:center;
				}
	
			}
		}
	}
	
}
.swiper-container{
	clear:both;
}
@media screen and (min-width:450px) and (orientation:landscape) {
	body { 
		background-color:#fff; 
		background-size: 85%; 
		background-image: url('../img/landscapemode.jpg'); 
		background-repeat: no-repeat; 
		background-position:center 0;
		opacity:0;
		position:fixed !important;
	}

}

#themeColor {
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.recently-view{
	.animated-background {
		height: 250px !important;
	}
	.collection-list-container{
		padding-top:72px;
	}
	& + .bottom-tabs-ul{
		display: none;
	} 
}
.unavailable_date_overlay{
	.max_nights_alert{
		.modal-body{
			.description{
				p{
					font-size: 16px;
				}
			}
			.btn.skip{
				width: 70%;
				font-size: 14px;
			}
		}
	}
	
}
.select-guests-modal{
	background-image: url('../img/calendar-image.png');
  background-repeat: no-repeat;
  background-position: bottom;
}


.image_cropper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image_cropper_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}

.crop_button {
  background-color: #00ba8c;
  color: #fff;
  width: 130px;
  margin-top: 40px;
  padding: 12px 0px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.lds-spinner {
	position: relative;
	margin: 0 auto;
}
.lds-spinner div {
  left: 94px;
  top: 48px;
  position: absolute;
  -webkit-animation: lds-spinner linear 1s infinite;
  animation: lds-spinner linear 1s infinite;
  background: #cccccc;
  width: 12px;
  height: 24px;
  border-radius: 40%;
  -webkit-transform-origin: 6px 52px;
  transform-origin: 6px 52px;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation-delay: -0.916666666666667s;
  animation-delay: -0.916666666666667s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -0.833333333333333s;
  animation-delay: -0.833333333333333s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.666666666666667s;
  animation-delay: -0.666666666666667s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.583333333333333s;
  animation-delay: -0.583333333333333s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.416666666666667s;
  animation-delay: -0.416666666666667s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.333333333333333s;
  animation-delay: -0.333333333333333s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.166666666666667s;
  animation-delay: -0.166666666666667s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.083333333333333s;
  animation-delay: -0.083333333333333s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.lds-spinner {
  width: 65px !important;
  height: 65px !important;
  -webkit-transform: translate(-32.5px, -32.5px) scale(0.325) translate(32.5px, 32.5px);
  transform: translate(-32.5px, -32.5px) scale(0.325) translate(32.5px, 32.5px);
}
.cropper_wrapper {
	position: relative;
	width: 100%;

	.back-btn-crooper{
			background-color: transparent;
			background-image: url(../img/common/common-sprite.svg);
			width: 35px;
			height: 20px;
			position: absolute;
			right: 0px;
			top: -40px;
			border: none;
			border-radius: 0;
			background-position: -393px -344px;
			-ms-transform: scale(1.5); /* IE 9 */
			-webkit-transform: scale(1.5); /* Safari 3-8 */
			transform: scale(1.2);
	}
}

.greyscale {
	filter:grayscale(100%)
}