
.otp{
    .otpImg img{
        width: 100%;
    }
    .otp-wrap{
        padding: 0 15px;
        .response{
            position:relative;
            padding:10px;
            height:20px;
            line-height:20px;
        }
        input[type="text"].refferal{
            margin-top:25px !important;
            text-transform:uppercase;
        }
        input[type="text"].refferal::-webkit-input-placeholder{
            text-transform:capitalize;
        }
        .text{
            margin-top: 30px;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: #7b7b7b;
        }
        .input-wrap{
            position:relative;
            .validation-error{
                position:absolute;
                bottom:-20px;
                left:0;
            }
        }
        input.form-control{
            width: 44px;
            height: 44px;
            border-radius: 3px;
            background-color: #f9f8f5;
            text-align: center;
            margin: 30px 0 0 10px;
            border: solid 1px #eeeeee !important;
        }
        input.form-control:first-child{
            margin-left:0; 
        }
        input[type=number].mobile{
            width: 80%;
            border-left: none;
            padding: 10px 10px;
            height: 44px;
            margin: 15px 0 0px 0 !important;
            border-radius: 3px;
            background-color: #f9f8f5 !important;
            border: solid 1px #eeeeee !important;
            box-sizing: border-box !important;
             -webkit-border-top-left-radius: 0px;
            -webkit-border-top-right-radius: 3px;
            -webkit-border-bottom-right-radius: 3px;
            -webkit-border-bottom-left-radius: 0px;
            -moz-border-radius-topleft: 0px;
            -moz-border-radius-topright: 3px;
            -moz-border-radius-bottomright: 3px;
            -moz-border-radius-bottomleft: 0px;
        }
        input[type=text], input[type=password],input[type=email]{
            width: 100%;
            padding: 10px 10px;
            height: 44px;
            margin: 15px 0 0px 0 !important;
            border-radius: 3px;
            background-color: #f9f8f5 !important;
            border: solid 1px #eeeeee !important;
            box-sizing: border-box !important;
            &.mobile{
                width: 80%;
                border-left: none;
                 -webkit-border-top-left-radius: 0px;
                -webkit-border-top-right-radius: 3px;
                -webkit-border-bottom-right-radius: 3px;
                -webkit-border-bottom-left-radius: 0px;
                -moz-border-radius-topleft: 0px;
                -moz-border-radius-topright: 3px;
                -moz-border-radius-bottomright: 3px;
                -moz-border-radius-bottomleft: 0px;
            }
        }
        .control{
            width: 100%;
            text-align: center;
        }
        .link{
            border: none;
            color: white;
            height: 54px;
            border-radius: 100px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 17px;
            width: 100%;
            margin-top:15px;
            line-height: 54px;
            cursor: pointer;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
            /* margin: 16px 15px; */
            right: 0;
            margin: 30px auto;
            span{
                font-size: 17px;
            }
           
        }
        .referal{
            font-size: 13px;
            font-weight: 500;
            text-align: right;
            margin-top:30px;
           // margin-bottom: 15px; 
            color: #00ba8c;
            
        }
        .termss{
            margin-top: 30px;
            font-size: 13px;
            font-weight: 500;
            text-align: center;
            color: #858585;
            span.time{
                display:inline;
            }
        }
        .step3{
            span{
                display: block;
                margin-top: 5px;
            }
            .text{
                color: #858585;
            }
            .referal{
                text-align: center;
                margin-top: 20px;
            }
        }

        .select-wrapper {
            border: solid 1px #eeeeee !important;
            border-top-left-radius: 3px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 3px;
            -webkit-border-top-left-radius: 3px;
            -webkit-border-top-right-radius: 0px;
            -webkit-border-bottom-right-radius: 0;
            -webkit-border-bottom-left-radius: 3px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-topright: 0px;
            -moz-border-radius-bottomright: 0;
            -moz-border-radius-bottomleft: 3px;
            background-color: #f9f8f5;
            border-right: none;
            float: left;
            position: relative;
            cursor: pointer;
            display: block;
            margin-top: 15px;
            width: 20%;
            height: 44px;
            .form-control.country-code {
                width: 100%;
                float: left;
                display: block;
                color: #7b7b7b;
                font-size: 14px;
                background-color: #f9f8f5;
                border: none;
                height: 42px;
                padding: 0 10px;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                background-repeat: no-repeat;
                cursor: pointer;
                z-index: 9;
                position: relative;
                &:focus{
                    outline: none!important;
                }
            }
        }
    }
    & + .bottom-tabs-ul{
        display: none;
    }
    
}
.btn:focus, .btn-large:focus, .btn-floating:focus{
    background-color:#00ba8c;
    box-shadow:none;
}
.btn:hover, .btn-large:hover{
    background-color:#00ba8c;
    box-shadow:none;
}
.optscreen{
    & + .bottom-tabs-ul{
        display: none;
    }
    
}
.error{
    text-align: center!important;
}
.success{
    text-align: center!important;
}
