
$green: #007e90;

.login-signup{
	background: $genric-color-w;
	padding: 15px;
	height: 100%;
	.header{
		background: $genric-color-w;
	}
	.middle-text{
		color: #7b7b7b !important;
		font-size: 18px;
	}
	.login-video{
		height: 336px;
		background-color:rgb(255, 222, 85);
		margin: -15px;
		overflow: hidden;
		position: relative;
		.skip{
			position: absolute;
			right: 20px;
			z-index: 9;
			font-size: 16px;
			top: 20px;
			width: 25px;
			height: 30px;
			background: url(../img/common/common-sprite.svg);
			background-position: -125px 626px;
			-webkit-transform: scale(1.3);
			-ms-transform: scale(1.3);
			transform: scale(1.3);
		}
		video{
			width: 100%;
			pointer-events: none;
		}
		.rh5v-DefaultPlayer_controls{
			display: none;
		}
	}
	.login-signup-wrap{
		text-align: center;
		position: relative;
		margin-top: 48px;
		width: 100%;
		
		.login-page-text{
			.heading{
				text-align: center;
				color: $color-primary;
				font-size: 20px;
				font-weight: 700;
				margin-bottom: 6%;
				margin-top: -20px;
			}
			.txt{
				text-align: center;
				color:rgb(133, 133, 133);
				font-size: 16px;
			}
		}
		.sec{
			width: 48%;
			float: left;
		}
		.sec:last-child{
			margin-left:4%;
		}
		.response{
            position:relative;
            padding:10px;
            height:20px;
            line-height:20px;
        }
		// .firstname-error{
		// 	float:left;
		// }
		// .lastname-error{
		// 	float: left;
		// 	margin-left: 3px;
		// }
		input[type=text]:-webkit-autofill {
		    background: #f2f2f2 !important;
		    font-size: 14px;
  			font-weight: 500;
		}
		.password{
			position: relative;
			input{
				padding-right: 60px !important;
			}
			.forgetpassword{
				position: absolute;
			    right: 0;
			    bottom: 28px;
			    width: 25%;
			}
			span{
				font-size: 13px;
			    font-weight: 500;
			    position: absolute;
			    right: 10px;
			    color: #00ba8c;
			    display: inline-block;
			    /* vertical-align: middle; */
			    transform: translateY(-50%);
			    -webkit-transform: translateY(-50%);
			    -moz-transform: translateY(-50%);
			}
		}
		span{
			transition: none!important;
			opacity: 1!important;
		}
		.line-wrap{
			margin-top: 35px;
			margin-bottom: 20px;
			.line{
				background:#e2e2e2;
				height: 1px;
			}
			.or{
				font-size: 14px;
				&:after{
					content: "or";
					position: absolute;
					top: 50%;
					text-align: center;
					left: 50%;
					transform: translate(-50%,-50%);
					-webkit-transform: translate(-50%,-50%);
					-moz-transform: translate(-50%,-50%);
				}
			}
		}
		.link{
		    border: none;
		    color: white;
		    height: 55px;
			border-radius: 100px;
		    text-align: center;
		    text-decoration: none;
		    display: inline-block;
		    font-size: 14px;
		    width: 100%;
		    margin-top: 12%;
		    line-height: 54px;
		    font-weight: 500;
		    cursor: pointer;
		    transition:none!important;
		    text-transform: none!important;
		    -webkit-box-shadow:0 0 6px 0 rgba(0, 0, 0, 0.2) !important;
		    -webkit-tap-highlight-color:none!important;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
			a{
				color:#fff;
				display: block;
			}
			.line-right{
				border-right: 1px solid rgb(0, 163, 123);
			}
		    span{
		    	font-size: 17px;
		    }
		    &.fb{
		    	background-color: #3b5998 !important;
				border: solid 1px #3b5998 !important;
				color: #fff !important;
				font-weight: 700;
				border-radius:3px; 
				margin-top: 5%;
				position: relative;
				&:after{
					content:"";
					position: absolute;
					border-top-left-radius: 3px;
					border-bottom-left-radius: 3px;
					width: 64px;
					height: 54px;
					top:-1px;
					left: -1px;
					background-color: #2E4282;
					background-image:url(../img/facebook-social-symbol.svg);
					background-size:30px;
					background-repeat: no-repeat;
					background-position: center;
				}

				// &:after{
				//   border: 16px solid #f3f3f3;
				//   border-radius: 50%;
				//   position:absolute;
				//   top:0;
				//   right:0;
				//   content:'';
				//   border-top: 16px solid #3498db;
				//   width: 120px;
				//   height: 120px;
				//   -webkit-animation: spin 2s linear infinite; /* Safari */
				//   animation: spin 2s linear infinite;
				// }

				// /* Safari */
				// @-webkit-keyframes spin {
				//   0% { -webkit-transform: rotate(0deg); }
				//   100% { -webkit-transform: rotate(360deg); }
				// }

				// @keyframes spin {
				//   0% { transform: rotate(0deg); }
				//   100% { transform: rotate(360deg); }
				// }
		    }
		    &.google{
		    	background-color: #d34332 !important;
				border: solid 1px #d34332;
				color: #fff !important;
				font-weight: 700;
				opacity: 1!important;
				transition:none !important;
				border-radius:3px;
				margin-top: 15px;
				position: relative;
				&:after{
					content:"";
					position: absolute;
					width: 64px;
					height: 54px;
					border-top-left-radius: 3px;
					border-bottom-left-radius: 3px;
					top: -1px;
					left: -1px;
					background-color: #963C32;
					background-image:url(../img/google-plus.svg);
					background-size:30px;
					background-repeat: no-repeat;
					background-position: center;
				}
		    }
		    &.green{
				background-color:$genric-color-w;
				color: $color-primary;
				border:1px solid $color-primary;
				box-shadow: 0px 3px 5px -2px rgba(0,0,0,0.5) !important;
				-webkit-box-shadow: 0px 3px 5px -2px rgba(0,0,0,0.5)!important;
		    	font-size: 17px;
		    	font-weight: 700;
		    	width:100%;
		    }
		    &.login{
				background-color:$color-primary;
				box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
				border: solid 1.5px $color-primary;
				font-size: 17px;
				color: $genric-color-w;
				font-weight: 500;
				position: relative;
				margin-top: 8%;
				left: 50%;
				bottom: 30%;
				-webkit-transform: translateX(-50%);
			    -ms-transform: translateX(-50%);
			    transform: translateX(-50%);
			    left: 50%;
				bottom: 0px;
				padding: 0;
		    }
		}
		.text{
			margin-top: 18%;
			font-size: 14px;
			font-weight: 500;
			color:  $color-primary;
		}
		.term{
			color: #858585;
			font-size: 13px;
			font-weight: 500;
			margin-top: 15%;
			span{
				color: $color-primary;

			}
		}
		.condition{
			color: #858585;
			font-size: 13px;
			font-weight: 500;
			margin-top: 7%;
			span{
				color: $color-primary;
			}
		}
		.middle {
		  width: 100%;
		  text-align: left;
		  float: left;
		  margin-top: 15px;
		  position: relative;
		  input[type="radio"] {
		    display: none;
		    &:checked {
		      + .box.front-end {
		        background-color:$genric-color-w;
		        border: solid 1px #00ba8c;
		        	span {
			          color:#00ba8c;
			          font-weight: 500;
			          &:before {
			            opacity: 1;
		                background: url(../img/common.svg);
		   				background-position: -131px -20px;
			          }
		        	}
		        
		      }
		      +.box.back-end{
		        background-color:$genric-color-w;
		        border: solid 1px #00ba8c;
		        	span {
			          color:#00ba8c;
			          font-weight: 500;
			          &:before {
			            opacity: 1;
		                background: url(../img/common.svg);
		   				background-position: -131px -98px;
			          }
		        	}
		      }
		    }
		  }
		  label{
		  	float: left;
		  	width: 100%;
		  }
		  // .or{
				// text-align: center;
				// display: inline-block;
				// vertical-align: middle;
				// font-size: 15px;
				// line-height: 54px;
				// font-weight: 500;
				// color: #cacaca;
		  // 	}
		  .box {
		    width: 100%;
			height: 54px;
			border-radius: 3px;
			background-color: #ffffff;
			border: solid 1px #eeeeee;
		    text-align: center;
		    cursor: pointer;
		    position: relative;
		    font-size: 13px;
			font-weight: 300;
			text-align: left;
			color: #cacaca;
		    
		    span {
		    	position: absolute;
		        top:50%;
		        left: 50%;
		        transform:translate(-50%, -50%);
		        -webkit-transform:translate(-50%,-50%);
		        -moz-transform:translate(-50%,-50%);
		      &:before {
		        display: block;
		        position:absolute;
		        left:-47px;
		        top:-9px;
		        opacity: 1;
		        font-weight: normal;
		        color: white;
		      }
		    }
		  }
		   .front-end {
		    span {
		      &:before {
		      	content: "";
		      	width: 35px;
				height: 35px;
		        background: url(../img/common.svg);
		        background-position: -39px -987px;
		      }
		    }
		  }
		  .back-end {
		    span {
		      &:before {
		        content: "";
		      	width: 35px;
				height: 35px;
		        background: url(../img/common.svg);
		        background-position: -39px -1056px;
		      }
		    }
		  }
		}
	
		.gender-button{
			width: 110px;
			height: 54px;
			border-radius: 3px;
			background-color: $color-primary;
			border: solid 1px $color-primary;
		}
		
		input[type=text], input[type=password],input[type=email]{
		    width: 100%;
		    padding: 10px 10px;
		    height: 55px;
		    margin: 15px 0 0px 0 !important;
		    font-size: 14px;
  			font-weight: 500;
		    outline:none!important;
		    box-shadow: none !important;
		    border: none !important;
		    border-radius: 3px;
			background-color: #f9f8f5 !important;
			border: solid 1px #eeeeee !important;
		    box-sizing: border-box !important;
		}
	}
	& + .bottom-tabs-ul{
		display: none;
	}
}
.password-wrapper{
	padding: 0 15px;
	margin-top: 50px;
	position:relative;
	.validation-error{
		color: #fde352;
		font-size: 14px;
	}
	.wrap{
		position:relative;
		.validation-error{
			color: #fde352;
			font-size: 14px;
			font-weight: 500;
			position:absolute;
			bottom:-20px;
		}
		// .response{
		// 	position:relative;
		// 	width:100%;
		// 	width:100%;
		// 	.error{
		// 		position:absolute;
		// 		left:0;
		// 		width:100%;
		// 		text-align:center;
		// 	}
		// }
	}
	
	.img{
		width: 92px;
		height: 92px;
		margin: 0 auto;
		background: url('../img/common/common-sprite.svg');
		&.reset{
			background-position: -581px -634px;
		}
		&.forgot{
			background-position: -578px -528px;
		}
	}
	.success{
		text-align: center !important;
	    color: #fddf49;
	    font-weight: 500;
	    font-size: 14px;
	}
	.error{
		text-align: center !important;
	    color: #fddf49;
	    font-weight: 500;
	    font-size: 14px;
	}
	input[type=text], input[type=password],input[type=email]{
		    width: 100%;
		    padding: 10px 10px;
		    height: 55px;
		    font-size: 14px;
  			font-weight: 500;
		    margin: 15px 0 0px 0 !important;
		    border-radius: 3px;
			background-color: #f9f8f5 !important;
			border: solid 1px #eeeeee !important;
		    box-sizing: border-box !important;
		    &.reset{
		    	margin-bottom: 15px !important;
		    }
		   
		}
	.text{
		color: $genric-color-w;
		margin-top: 24px;
		margin-bottom: 5px;
		text-align: center;
		font-size: 17px;
	}
	.small-text{
		color: #ffffff;
		font-size: 14px;
		margin-top: 8px;
		text-align: center;
	}
	
	.link{
		    border: none;
		    color: white;
		    height: 54px;
			border-radius: 100px;
		    text-align: center;
		    text-decoration: none;
		    display: inline-block;
		    font-size: 14px;
		    width: 100%;
		    margin-top:30px;
		    line-height: 54px;
		    cursor: pointer;
		    span{
		    	font-size: 17px;
		    }
		    &.fb{
		    	background-color: #3b5998;
				border: solid 1px #3b5998;
		    }
		    &.google{
		    	background-color: #d34332;
				border: solid 1px #d34332;
		    }
		    &.green{
		    	background-color: $color-primary !important;
		    	font-size: 17px;
		    }
		    &.white{
				background-color: #ffffff;
				box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
				border: solid 1.5px $color-primary;
				font-size: 17px;
				color: $color-primary;
				font-weight: 500;
		    }
		    &.margin{
		    	margin-top: 60px;
		    }
		}
	& + .bottom-tabs-ul{
		display: none;
	}
}
form input.error {
  border-color: red;
}
.validation-error{
	display: block;
    color: #f26565;
    font-weight: 500;
    margin-top: 5px;
    text-align: left;
}
.validation_error{
	display: none;
	color: #f26565;
    font-weight: 500;
    margin-top: 5px;
	text-align: left;
	&.show{
		display: block;
	}
}

.signup-container{
	& + .bottom-tabs-ul{
		display: none;
	}
}
.responseAPI{
	position: fixed;
	z-index: 10;
    width: 100%;
    padding:20px 30px;
    color: #fff;
    bottom: 0;
    left: 0;
    transition: 0.3s all;
    transform: translateY(100%);
	opacity:0;
    background-color: #fcde66;
  	box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.1);
    .error{
    	color: #7b7b7b;
    	font-size:12px;
    	font-weight:500;
    	word-break: break-word;
    	span{
    		position:relative;
    		&:before{
    		    content: "";
			    width: 27px;
			    height: 26px;
			    position: absolute;
			    left: -31px;
			    background-repeat: no-repeat;
			    top: -6px;
				background-image:url('../img/error-img.png');
    		}
    	}
    }
    .success{
    	color:#fff
	}
	&.offline {
		bottom: 63px;
	}
    &.true{
    	transform: translateY(0%);
		opacity:1;
		z-index: 10;
    }
}
// .login-signup .login-signup-wrap input.invalid-field, .login-signup .login-signup-wrap input.invalid-field.rw-widget, .login-signup .login-signup-wrap input.invalid-field.rw-widget:hover, .login-signup .login-signup-wrap input.invalid-field.rw-state-focused {
//     border-color: #f26565 !important;
//     background-color: rgba(242, 101, 101, 0.07) !important;
// }