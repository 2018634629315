// mixing
@mixin border-box {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important; 
}
%clearfix {
    *zoom: 1;
    &:before, 
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}
*,
*:after,  
*:before {
    margin: 0;
    padding: 0;
    @include border-box;
 
}
.row {
    @extend %clearfix;
}
.clearfix{
  @extend %clearfix ;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@for $i from 1 through 12 {
    .col-#{$i} { 
        width: 100% / 12 * $i;
    }
}
[class*='col-'] {
    float: left;
    position: relative;
    min-height: 1px; 
    @include border-box;
} 
@mixin headline ($color, $size) {
  color: $color;
  font-size: $size;  
}

@mixin font-weight($weight) {
  $weights: (
    extra-thin: 100,
    thin: 300,
    medium: 500,
    bold: 700,
    extra-bold: 700,
  );
  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }
  font-weight: $output;
}
@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}

@mixin translateX($x) {
   @include transform(translateX($x));
}
@mixin translateY($y) {
   @include transform(translateY($y));
}