
.viewInvoiceHeader {
    background:$genric-color-w;
    box-shadow:rgba(0,0,0,0.05) 0px 1px 20px;
    height:60px;
}
.viewInvoiceModal{
    .responseAPI{
        background: #00ac8f;
        color:#ffffff;
        .error{
            color:#ffffff;
            span:before{
                display:none;
            }
        }
    }
}
.viewInvoice{
    // border-bottom: 1px solid #f2f2f2;;
    // margin:22px 15px 0 15px;
    // padding-bottom: 15px;

    .heading{
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        color: #00ba8c;
    }
    .voucher{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin-top: 12px;
        color: #a8a8a8;
    }
    .text{
        font-size: 14px;
        font-weight: 500;
        margin-top: 8px;
        text-align: center;
        color: #858585;
    }
    .label{
        margin-top: 26px;
        td{
            vertical-align: baseline;
            padding: 10px 0px;
        }
        .title{
            width: 37%;
            font-weight: 500;
            font-size: 13px;
            color: $color-primary;
        }
        .name{
           color: #9b9b9b;
        }
    }
    .table{
        td{
            width: 50%;
            padding: 10px 0px;
            vertical-align: baseline;
            .wrap{
                display: inline-block;
                span{
                    display: block;
                    text-align: left;
                }
                .name{
                    font-size: 13px;
                    font-weight: 500;
                    text-align: left;
                    color: #00ba8c;
                }
                .subname{
                    font-size: 13px;
                    font-weight: 500;
                    text-align: left;
                    color: #9b9b9b;
                }
            }
            .icon{
                width: 24px;
                height: 25px;
                background: url(../img/common/common-sprite.svg);
                display: inline-block;
                vertical-align: top;
                &.checkin{
                    background-position: -188px -670px;
                }
                &.checkout{
                    background-position: -190px -722px;
                }
                &.guest{
                    background-position: -235px -708px;
                }
                &.rooms{
                    background-position: -235px -755px;
                }
            }
        }
    }
}
.overlay-modal .content-modal .modal-body{
    height: 100%;
}

.view-invoice{
    padding-bottom: 0px;
    .button:focus{
        background-color:#fff;  
        color: #00ba8c;
    }
    .invoice-details{
        padding: 10px 0 30px 0;
        margin:0 15px 25px 15px;
        border-bottom: 1px solid #f2f2f2;
        .heading{
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            color: #00ba8c;
        }
    }
    .card-text{
        font-size: 11.5px;
        font-weight: 500;
        line-height: 1.39;
        text-align: left;
        color: #00ba8c;
    }
    .booking-amount{
        .green-color{
            padding: 11px 0;
        }
    }
    .final-amount{

        .cost{
            color: #fff!important;
            border-radius: 2px;
            padding: 11px 0;
            background-color: #00ba8c;
            margin-right: -15px;
            padding-right: 15px;
        }
    }
}
.view-invoice{
    margin-top:5px;
}
.policy{
        font-size: 12px;
        font-weight: 500;
        line-height: 1.25;
        text-align: left;
        color: #a4a4a4;
        margin: 15px;
    }
.button{
        width: 290px;
        height: 54px;
        margin: 30px auto;
        border-radius: 100px;
        background-color: #ffffff;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1.5px #00ba8c;
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        color: #00ba8c;
        &:focus{
            outline: none;
            background-color: #2ab7a9;
            color: #fff;
        }
}