
* {
	box-sizing: border-box;
}
body {
	height: 100%;
	float: left;
	width: 100%;
}
.trip{
	margin: 15px;
	position: relative;
	.booking-tile{
		.tile{
			.price{
					margin-top: 10px;
			}
		}
	}

}
.trip-container {
	margin-top:73px;
	padding-bottom: 30px;
	.booking-tile .response .text.canceled{
		color: #f26565;
	}
	&.fromWallet{
		& + .bottom-tabs-ul {
			display:none;
		}
	}
	 .button{
        height: 35px;
        border-radius: 100px;
        line-height: 35px;
        margin: 0 auto;
        margin-top: 15px;
        border: none;
        outline: none;
        display: block;
        width: 208px;
        background-color: #a7a7a7;
        box-shadow: 0 2px 12px 0 rgba(114, 114, 114, 0.2);
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
    }
}


.cancel-by-user{
	margin-top: 30px;
	.heading{
		font-size: 16px;
		font-weight: 500;
		text-align: left;
		color: $heading-color;
		.icon{
			width: 28px;
			height: 24px;
			margin-left: 15px;
			background: url(../img/common/common-sprite.svg);          
			display: inline-block;
			vertical-align: middle;
			&.request-details{
				background-position: -186px -528px;
			}
			&.property-details{
				background-position: -232px -528px;  
			}
			&.booking-Info{
			  background-position: -186px -775px;  
			}
			&.cancellation{
				background-position: -229px -574px; 
			}
		}
	}
	.box{
		background: $genric-color-w;
		padding: 25px 15px 0 15px;
		margin-top: 16px;
		.wrap{
			margin-bottom:30px; 
			.title{
				font-size: 15px;
				font-weight: 500;
				text-align: left;
				color: #7b7b7b;
			}
			.subtitle{
				font-size: 13px;
				font-weight: 500;
				text-align: left;
				color: #858585;
			}
		}
		.icon{
			float: left;
			height: 26px;
			&.request-details{
				 background-position: -186px -568px; 
			}
			&.guest{
				background-position: -186px -617px;
			}
			&.checkin{
				background-position: -186px -668px;
			}
			&.checkout{
				background-position: -188px -721px;
			}
		}
	}
	.request-wrap {
		padding:15px 0px;
		background: #fff;
		border-top: 1px solid #f2f2f2;
		.modify-btn{
			margin-top: 10px;
		}
		i.arrow-icon{
			margin-left: 3px;
		    border: solid #f26564;
		    border-width: 0 2px 2px 0;
		    display: inline-block;
		    padding: 1px;
		    background: none;
		    -ms-transform: rotate(-45deg);
		    transform: rotate(-45deg);
		    -webkit-transform: rotate(-42deg);
		    height: 8px ;
		    width: 8px ;
		}
		
	}
	.button.fb{
		  width: 246px;
		  height: 60px;
		  border-radius: 100px;
		  font-size: 13px;
  		  font-weight: 500;
		  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
		  background-color: #3b5998;
		  border: solid 1px #3b5998;
	}
}
.host-details{
	margin-top: 25px;
	.box{
		padding: 15px;
		table{
			width: 100%;
			margin-bottom: 20px;
			td{
				padding:10px 0;
				font-size: 14px;
				font-weight: 500;
				text-align: left;
				color: #858585;
				vertical-align: baseline;
				&.lable{
					width:25%;
				}
				&.seperate{
					width: 5%;
				}
			}
		}
		.btn{
			font-size: 15px;
			font-weight: 500;
			text-align: center;
			color: #00ba8c;
			width: 137px;
			margin-bottom: 10px;
			height: 40px;
			line-height: 40px;
			border-radius: 100px;
			background-color: #ffffff;
			border: solid 1px #00ba8c;
			display:inline-block;
		}
	}
}
.cacelation-policy{
	margin-top: 25px;
	.title{
		font-size: 15px;
		font-weight: 500;
		text-align: left;
		color: #858585;
		margin-bottom: 15px;
	}
	.subtitle{
		font-size: 13px;
		font-weight: 500;
		line-height: 1.38;
		padding-bottom: 15px;
		text-align: left;
		color: #858585;
	}
	.booking-date{
		font-size: 13px;
	}
	.request-wrap{
		.modify-btn{
			font-size: 13px;
			font-weight: 500;
			color: #f26565;
		}
		span.arrow-icon{
			
		}
	}
	
}

.trip-detail {
	margin-top: 70px;
	.booking-property-details {
		position:relative;
		margin-top:0;
		.heading{
			margin-left:-6px;
		}
		.subheading{
			margin-left:-6px;
		}
		.property-image {
			background-image:url(../img/trip-list-placeholder.jpg);
			padding-top:70%;
		}
		.property-link {
			background: url(../img/common/common-sprite.svg);
			background-position: -183px -798px; 
			width:7px;
			height:11px;
			position:absolute;
			bottom:0;
			right:0;
			padding: 19px 15px;
			box-sizing: content-box !important;		
			z-index: 99;
		}
	}
}
.pasttrip{
	 margin-top: 73px;
   	 position: relative;
   	 & + .bottom-tabs-ul{
        display: none;
    }
    .booking-tile .response .text.canceled{
		color: #f26565;
	}
}
