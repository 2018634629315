.bottom-tabs-ul {
    background-color: $genric-color-w;
    bottom: 0;
    height: 63px;
    position: fixed;
    width: 100%;
    z-index: 11;
    @include transition(all 0.5s);
    .bottom-tabs {
        padding: 10px 5px;
        border-top: 1px solid #ebebeb;
        margin: 0px;
        height: 100%;
        font-size: 0px;

        .bottom-tab {
            font-size: 10px;
            display: inline-block;
            width: 20%;
            position:relative;
            text-align: center;
            .request-count{
                width: 16px;
                height: 16px;
                border-radius: 100px;
                color: #fff;
                font-size: 9px;
                display:none;
                top: -1px;
                right: 14px;
                position: absolute;
                background-color: #00ba8c;
                border: solid 1px #ffffff;
                line-height: 15.99px;
                &.active{
                    display: inline-block;
                    }
                }
            a {
                @include headline($color-dark-grey, $font-small-2);
                @include font-weight(bold);
                
            }
            i {
                background-image:url($sprite-image);
                height:20px;
                display:block;
                margin:0 auto 2px;
                &.icon-explore {
                    width:21px;
                    background-position:-17px -408px;
                }
                &.icon-request {
                    width:21px;
                    background-position:-71px -18px;
                }
                &.icon-trips {
                    width:18px;
                    background-position:-72px -126px;
                }
                &.icon-talk-us {
                    width:25px;
                    background-position:-69px -234px;
                }
                &.icon-account {
                    width:19px;
                    background-position:-72px -342px;
                }
            }            
            &.active {
                transform:scale(1.05);
                .request-count{
                    width: 16px;
                    height: 16px;
                    border-radius: 100px;
                    color: #fff;
                    display:none;
                    font-size: 9px;
                    top: -1px;
                    right: 14px;
                    position: absolute;
                    background-color: #00ba8c;
                    border: solid 1px #ffffff;
                    line-height: 15.99px;
                    &.active{
                        display:inline-block;
                    }
                }
                a {
                    color:$color-primary;
                }
                i {
                    &.icon-explore {
                        background-position:-17px -353px;
                    }
                    &.icon-request {
                        background-position:-13px -463px;
                    }
                    &.icon-trips {
                        background-position:-72px -72px;
                    }
                    &.icon-talk-us {
                        background-position:-69px -180px;
                    }
                    &.icon-account {
                        background-position:-72px -288px;
                    }
                }

            }
        }
    }
}