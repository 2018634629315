.btn {
	background:$color-primary;
	height:44px;
	@include border-radius(22px);
	padding:0 32px;
	color:#fff;
	line-height:44px;
	box-shadow:none;
	text-transform:capitalize;
    border: none;
}