// reset css
* {
    /* Disable selection/Copy of UIWebView */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none; 
    font-family:MuseoSans !important;
    -webkit-text-size-adjust: 100%;
}
*,
*:after,  
*:before {
    margin: 0;
    padding: 0;
    @include border-box;
}
body {
  @include headline($text-color,$font_12);
  font-family:$font-stack !important;
  background-color:#F9F8F5;
  @include font-weight(medium);
  @include border-box;
}
input,textarea {
    /* Exception for input areas */
    -webkit-touch-callout: default !important;
    -webkit-user-select: text !important;
    border: none !important;
    outline: none !important;
    box-shadow:none !important;
}
::-webkit-input-placeholder {
  color:$genric-color-w;
}
ul {
  list-style-type: none;
}
a{
  text-decoration:none;
}
::-webkit-scrollbar { 
    display: none; 
}
