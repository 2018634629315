/* elements */
@import "./base/_variables.scss";
@import "./base/_mixins.scss";
@import "./base/_reset.scss";
@import "./base/_typography.scss";
@import "./base/_common.scss";

/* common components - bottom tabs */
@import "./layouts/_bottom-tabs.scss";
@import "./components/_buttons.scss";

@import "pages/account.scss";
@import "pages/booking.scss";
@import "pages/collection.scss";
@import "pages/home.scss";
@import "pages/loginSignup.scss";
@import "pages/otp.scss";
@import "pages/prepayment.scss";
@import "pages/property-preview.scss";
@import "pages/search.scss";
@import "pages/trip.scss";
@import "pages/viewInvoice.scss";



body {
    margin-bottom: 60px;
}

.map_full_view + .bottom-tabs-ul {
    display: none;
}

.map_full_view {
    height: 100vh;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    bottom: 150px !important;
}