.about-property-modal {
	height:100%;
	.modal-body {
		padding:0 15px;
		height:calc(100% - 48px);
		overflow-y:scroll;
		-webkit-overflow-scroll:touch;
		.description-section {
			padding:20px 0;
			border-bottom:1px solid $color-light-grey;
			&:last-child {
				border:none;
			}
			h3 {
				@include headline($color-dark-grey,$font_14);
				@include font-weight(bold);
				margin:0 0 10px;
			}
			p {
				@include headline($color-dark-grey,$font_14);
				@include font-weight(thin);
				line-height:22px;
			}
		}
	}

}