.search-tile {
  background:$genric-color-w;
  @include border-radius(4px);
  padding:10px;
  margin-bottom:10px;
  .imgb {
	padding-top:77%;
	margin-bottom:15px;
	position:relative;
	@include border-radius(3px);
	.search-slider {
	  position:absolute;
	  top:0;
	  left:0;
	  right:0;
	  bottom:0;
	  width:100%;
	  height:100%;
	  overflow:hidden;
	  .slide {
		width:100%;
		height:100%;
		background-size:cover;
		background-position:center center;
		background-repeat: no-repeat;
		background-image:url(../img/search_placeholder.jpg);
		@include border-radius(3px);
		background-color:#eee;
	  }
	  .owl-stage-outer, .owl-stage, .owl-item {
		height:100%;
	  }
	  .owl-item {
		float:left;
	  }
	}
	.tile-flag {
	  background-color:#fcde66;
	  @include headline(#8d7e3e,$font_13);
	  @include border-radius(3px);
	  @include font-weight(bold);
	  text-align:center;
	  padding:8px;
	  position:absolute;
	  top:-5px;
	  left:5px;
	  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	  span {
		@include font-weight(medium);
		font-size:$font_12;
		display:block;
		padding-top:4px;
	  }
	  &.discount {
		font-size:$font_14;
		line-height:17px;
		@include border-radius(0 0 100px 0);
		top:0;
		left:0;
		padding:5px 20px 15px 10px;
		box-shadow:none;
	  }
	}
	.wishlist-icon {
	  background-image:url($sprite-image);
	  background-position:-121px -11px;
	  width:31px;
	  height:28px;
	  position:absolute;
	  right:12px;
	  top:12px;
	  display:block;
	  &.active {
		background-position:-67px -457px;
	  }
	}
	.video-tag {
	  margin-bottom:8px;
	  float:left;
	  height:32px;
	  @include headline($primary-color,$font_12);
	  background-color: $genric-color-w;
	  line-height:18px;
	  padding:7px 13px;
	  @include border-radius(16px);
	  position:absolute;
	  right:12px;
	  bottom:12px;
	  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	  i{
		float:left;
		width:21px;
		height:18px;
		margin-right:6px;
		background-image:url($sprite-image);
		background-position:-180px -288px;
		background-clip: padding-box;
	  }
	}
  }
  .txtb {
	.tile-rating {
	  // background:#7fd482;
	  @include headline($genric-color-w,$font_12);
	  width:28px;
	  height:28px;
	  text-align:center;
	  line-height:28px;
	  @include border-radius(50%);
	  display:block;
	  margin-left:10px;
	}
	h2 {
	  @include headline($heading-color,$font_17);
	  @include font-weight(medium);
	  line-height:21px;
	  margin-bottom:5px;
	  display: -webkit-box;
	  max-width: 400px;

	  -webkit-line-clamp: 2;
	  margin:0 0 5px;
	}
	.features {
	  padding:10px 0 18px;
	  span {
		float:left;
		margin-right:20px;
		line-height:22px;
		height:18px;
		@include font-weight(medium);
		@include headline($text-color,$font_13);
	  }
	  i{
		float:left;
		width:18px;
		height:19px;
		margin-right:12px;
		background-image:url($sprite-image);
		background-position:-127px -72px;
		background-clip: padding-box;
		&.guest {
		  background-position:-127px -126px;
		}

	  }
	}
	.bottom-txtb {
	  padding-bottom:5px;
	}
	.col-50 {
	  width:50%;
	  float:left;
	}
	.tag{
	  float:left;
		height:32px;
		font-size: $font_11;
		line-height:20px;
	  padding:6px;
	  @include border-radius(3px);
	  i{
		float:left;
		width:26px;
		height:22px;
		margin: -2px 6px 0 0;
		background-image:url('../img/tag_icon.svg');
		background-clip: padding-box;
	  }
	}
	.payment-note {
	  @include headline($primary-color, $font_13);
	  line-height:15px;
	  float:left;
	  margin-top:8px;
	}
	.price {
	  span {
		display:block;
	  }
	  .discount {
		@include headline($primary-color,$font_17);
		@include font-weight(bold);
		line-height:20px;
		height:20px;
		margin-bottom:4px;
		margin-right:5px;
		margin-top:8px;
		del {
		  @include headline(#b3b1b1,$font_15);
		  @include font-weight(medium);
		  margin-right:2px;
		}
	  }
	  .final-price {
	  	padding-top:2px;
		@include headline(#6c6c6f,$font_20);
		line-height:30px;
		margin-right:5px;
		float:right;
		height:28px;
		.instant-book-icon {
		  width: 28px;
		  height: 28px;
		  float:left;
		  margin-right:8px;
		  background-image:url($sprite-image);
		  background-position:-122px -176px;
		  background-clip: padding-box;
		}
	  }
	}
  }
}