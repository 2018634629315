
@import "../layouts/modal/_search-location.scss";
@import "../layouts/modal/recently-viewed.scss";
/* search container */
.search-bar-container {
	position:absolute;
	padding: 0 20px;
	bottom: 30px;
	z-index: 9;
	width: 100%;
	.search-input-box {
		background-color:$genric-color-w;
		width:100%;
		height:60px;
		line-height:60px;
		padding-left:30px;
		@include headline(#adadad, $font_14);
		@include border-radius(30px);
		-webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
		-moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
		span {
			background-color:$color-primary;
			width:70px;
			height:100%;
			@include border-radius(0 33px 33px 0);
			position:relative;
		}
		.search-icon {
			position: absolute;
			width: 21px;
			height: 21px;
			background-image: url($sprite-image);
			background-position: -17px -75px;
			left: 50%;
			top: 50%;
			@include translate(-50%, -50%);
		}
	}
	&.active {
		padding: 0;
		position:fixed;
		top:0;
		left:0;
		bottom:inherit;
		@include translate(0,0);

		.search-input-box {
			@include border-radius(0);
			span {
				@include border-radius(2px 0 0 2px);
			}
		}


	}
}

/* showcase */
.outer-showcase {
	background-color: $color-light-grey;
	width:100%;
	min-height:366px;
	position:relative;
	z-index: 10;
	.logo{
		position:absolute;
		top:15px;
		left:15px;
		z-index:99;
		width:50%;
	}
}
.showcase {
	background-color: $color-light-grey;
	background-image:url(https://d39vbwyctxz5qa.cloudfront.net/home_banners/mobile_web_banners/Goa.jpg?v1);
	background-size:cover;
	background-position:center;
	padding-top: 98%;
	position:relative;

	h1 {
		@include headline($genric-color-w, 20px);
		@include font-weight(700);
		line-height: 28px;
		text-align:center;
		position:absolute;
		text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.5);
		top:54%;
		left: 50%;
    	transform: translate(-50%,-50%);
    	letter-spacing:1px;
    	width: 100%;
	}
}

/* generic home heading section */
.home-heading-section {
	.see-all {
		@include headline($color-primary,$font-13);
		@include font-weight(500);
		&.right{
			position:absolute;
			right:15px;
		}
	}

	h2.heading {
		@include headline($color-primary,$font-20);
		margin: 0;
	}

	.description {
		@include headline($color-dark-grey,$font-16);
		@include font-weight(thin);
		line-height:18px;
		margin-top: 9px;
		display: block;
	}
}

/* trending section */
.trending-section {
	background:$genric-color-w;
	padding:30px 0px;
	.heading-section {
		@extend .home-heading-section;
		padding:0 15px;
	}
	.trending-inner-section {
		margin: 22px 0 10px;
		padding: 0 5px;
		//margin-bottom: 30px;
		min-height:300px;
		.trending-item {
			margin: 5px 11px 30px; 

			.imgb {
				background-color: $color-light-grey-2;
				background-size: cover;
				background-position: top center;
				width: 100%;
				padding-top: 100%;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				border-radius: 50%;
				pointer-events:none;

			}

			h3 {
				pointer-events:none;
				@include headline($color-dark-grey, $font_16);
				@include font-weight(thin);
				margin:12px 0 0;
				span{
					@include font-weight(medium);
					@include headline($color-dark-grey, $font_12);
					display:block;
					padding-top:4px;
				}
			}
		}
	}
	.see-all {
		@include headline($color-primary,$font-15);
		text-align:center;
		width:20%;
		display:inline-block;
	}
}
.trending-cities {
	.trending-section {
		margin:0 !important;
		padding:0 !important;
		.trending-item {
			margin-bottom:25px;
		}
	}
}


/* recent section */
.recent-section {
	margin-bottom:35px;

	.heading-section {
		@extend .home-heading-section;
		padding:30px 15px 25px 15px;
		h2.heading{
			font-size:20px;
			color: #7b7b7b;
		}
	}

	.recent-inner-section {
		.recent-item-tile {
			box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
			&.see-all-tile {
				height: calc(100% + 5px)!important;
				background: #7fdbc5;
				@include headline($genric-color-w, $font-18);
				@include font-weight(thin);
				span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}
			}

			.imgb {
				background-image:url(../img/search_placeholder.jpg);
				background-color: $color-light-grey-2;
				background-size: cover;
				background-position: top center;
				width: 100%;
				padding-top:67%;
				@include border-radius(2px);
				.wishlist-icon {
					background-image: url($sprite-image);
					position: absolute;
					width: 30px;
					height: 27px;
					top: 15px;
					right: 15px;
					background-position:-121px -12px;
					&.active {
						background-position:-67px -458px;
					}
				}
			}

			.description {
				background-color: $genric-color-w;
				padding:15px 12px;
				float:left;
				width:100%;
				.row {
					margin:0;
				}

				h3 {
					@include headline($color-dark-grey, $font-16);
					@include font-weight(medium);
					margin: 0px 0px 5px;
				}

				.location {
					@include headline($color-dark-grey, $font-16);
					@include font-weight(thin);
					line-height:18px;
					display: block;
					white-space: nowrap; 
					overflow: hidden;
    				text-overflow: ellipsis;
				}

				.price {
					@include headline(#838383, 17px);
					margin-top: 7px;
					display: block;
					line-height:23px;
				}

				.rating {
					position: absolute;
    				right: 0;
					width: 28px;
				    height: 28px;
				    background-color: #7fd482;
				    color: #fff;
				    font-size: 12px;
				    border-radius: 50%;
				    font-weight: 700;
				    display:block;
				    line-height: 28px;
				    text-align: center;
				}
	
			}
		}
	}
}

/* property types */
.property-type-section {
	overflow-x:hidden;
	.heading-section {
		@extend .home-heading-section;
	}

	.property-type-inner-section {
		padding:20px 0 0 15px;
		.property-type-item {
			text-align: center;
			margin-bottom: 30px;

			.imgb {
				background-color: $color-light-grey-2;
				background-size: cover;
				background-position: top center;
				width: 100%;
				padding-top: 100%;
				@include border-radius(50%);
			}

			h3 {
				color: $color-dark-grey;
				font-size: $font-small-3;
				font-weight: 300;
				margin: 8px 0 0;
			}
		}
	}
}

/* Offer banners */
.offers-section {
	overflow-x:hidden;
	.heading-section {
		@extend .home-heading-section;
		margin-bottom:25px;
	}
	.minheaight{
		min-height: 117px;
		width: 100%;
		box-shadow:none;
		img{
			width: 100%;
		}
	}
	.offers-inner-section {
		.offer-item {
			div{
				pointer-events: none;
			}
			.imgb {
				background-color: $color-light-grey-2;
				background-size: cover;
				background-position: top center;
				width: 100%;
				padding-top: 55%;
			}
		}
	}
	.swiper-pagination {
		bottom:13px !important;
		.swiper-pagination-bullet {
			width:10px;
			height:10px;
			opacity:1;
			background:transparent;
			border:1px solid $genric-color-w;
		}
		.swiper-pagination-bullet-active {
			background: $genric-color-w;
		}
	}
}

/* gh features */
.features-section {
	padding:28px 20px 0px 20px;
	overflow-x:hidden;
	background:#fff;
	.heading-section {
		@extend .home-heading-section;
		margin-bottom:28px;
	}
	.features-inner-section {
		background:$genric-color-w;
		padding:0px 0 10px 0;

		.features-item {
			text-align: center;
			margin-bottom: 30px;
			.imgb {
				background-image:url(../img/feature.png);
				background-color:#fff;
				width:100px;
				height:100px;
				margin: 0 auto;
				@include border-radius(50%);
				&.feature1{
					background-image:url(../img/feature.png);
					background-color:#fff;
					background-size: 115px;
    				background-position: -8px -6px;
				}
				&.feature2{
					background-image:url(../img/feature.png);
					background-color:#fff;
					background-size: 115px;
   					background-position: -8px -212px;
    			}
				&.feature3{
					background-image:url(../img/feature.png);
					background-color:#fff;
					background-size: 115px;
    				background-position: -8px -317px;
				}
				&.feature4{
					background-image:url(../img/feature.png);
					background-color:#fff;
					background-size: 115px;
    				background-position: -8px -109px;
				}
			}
			.txtb {
				text-align:center;
				padding: 0 5px;
				h3 {
					font-size:$font_15;
					@include font-weight(medium);
					margin: 13px 0 7px;
				}
				p {
					@include font-weight(thin);
					line-height:17px;
				}
			}
		}
	}
}

/* refer and earn */
.refer-earn-section {
	padding:23px 15px;
	height:208px;
	background-size:cover;
	margin-bottom:20px;
	h3 {
		@include headline($color-dark-grey-2, $font-20);
		@include font-weight(bold);
		margin: 0 0 12px;
	}
	p {
		font-size: $font_15;
		line-height:22px;
		margin-bottom:20px;
	}
	.btn {
		background:$genric-color-w;
		@include headline($color-primary, $font_15);
		padding:0 19px;
		height:40px;
		@include border-radius(20px);
		line-height:40px;
	}
}



// Modals
.overlay-modal {
	&.calendar {
		z-index: 1000;
		@include transition(all 0.4s linear);
		@include translateY(100%);
		&.active {
			@include translateY(0);
		}
		&.active-out {
			@include translateY(100%);
		}
	}
	&.guest-modal-overlay {
		z-index: 1000;
	}
	.date-range-calendar {
		&.content-modal {
			background-color: $color-primary;
			.modal-header {
				height:48px;
				padding: 15px 20px;
				span {
					 color:$genric-color-w;
					 padding:15px !important;
				}
			}
			.modal-body {
				height: calc(100% - 48px) !important;
				overflow: hidden;
				.calender-header-outer {
					padding: 0 15px;
					min-height: auto;
					position:relative;
					.calendar-gradient-layer {
						width:100%;
						height:30px;
						position:absolute;
						bottom: -65px;
						left: 0px;
						background-image:url(../img/calendar-gradient.png);
						z-index: 9;
					}
				}
				.calender-header {
					width:100%;
					background-color:#2d9c7e;
					color:$genric-color-w;
					border-radius: 25px;
					height:50px;
					line-height:50px;
					position:relative;
					font-size:$font-15;
					text-align:center;
					span {
						float:left;
						width:50%;
						height:50px;
						padding:0 10px;
						&.checkout {
							float:right;
						}
						&.arrow{
							width:16px;
							height:15px;
							background-image: url($sprite-image);
							background-position: -182px -74px;
							position:absolute;
							top:50%;
							left:50%;
							@include translate(-50%,-50%);
						}
					}
				}

				.Cal__Container__root {
					margin:0 auto;
					height: calc(100% - 50px);
					display: block;
				}
				.Cal__Header__root {
					display:none;
					background-color:transparent !important;
					padding: 0 15px !important;
					min-height:auto !important;
					height:50px;
					line-height:50px !important;
					margin-bottom:10px;
					.Cal__Header__wrapper {
						background-color: #2d9c7e;
						@include border-radius(25px);
						padding: 0 35px;
						.Cal__Header__dateWrapper  {
							font-size:$font_15;
							line-height:50px;
							height:50px;
							&.Cal__Header__year {
								display:none;
							}
						}
					}
					.Cal__Header__range{
						background-color: #2d9c7e;
						text-align:center;
						@include border-radius(25px);
						.Cal__Header__wrapper {
							background-color: #2d9c7e;
							@include border-radius(25px);
							&::after,&::before {
								border:none;
								margin:0px;
								width:16px;
								height:15px;
								top:50%;
								@include translate(-50%, -50%);
								background-image: url($sprite-image);
								background-position:-182px -74px;
							}
							.Cal__Header__dateWrapper {
								font-size:$font_15;
								&.Cal__Header__year {
									display:none;
								}
							}
						}
					}
				}
				.Cal__Weekdays__root {
					background-color:transparent !important;
					box-shadow:none !important;
					padding: 0 15px;
				}
				.Cal__Container__listWrapper {
					background:transparent !important;
					.Cal__MonthList__root > div {
						height:auto !important;
						& > div {
							position:relative !important;
							top:0 !important;
							&:first-child {
								margin-top:20px !important;
							}
							&:last-child {
								margin-bottom:38px !important;
							}
						}
					}
					.Cal__MonthList__root {
						background:transparent !important;
						padding:0 10px 110px;
						height:calc(100vh - 147px) !important;
						overflow-x:hidden !important;
						.Cal__Month__rows {
							background:transparent !important;
							top:36px;
							padding: 0 5px;
							.Cal__Month__row {
								box-shadow:none !important;
								-webkit-box-shadow:none !important;
								li {
									&.Cal__Day__root {
										box-shadow:none;
										-webkit-box-shadow:none;
										background:transparent;
										@include headline($genric-color-w, $font_15);
										@include font-weight(medium);
										&:first-child {
											.Cal__Day__month, .Cal__Day__year {
												top:-36px;
												bottom:inherit;
												line-height:36px;
												display:block !important;
												font-size:$font_15;
												@include font-weight(bold);
											}
											.Cal__Day__month {
												border-top: 1px solid rgba(255, 255, 255, 0.2);
												// text-indent:-37px;
												width:calc(100vw - 20px);
												color:$genric-color-w !important;

											}
											.Cal__Day__year {
												border-top: 1px solid transparent;
												text-indent:34px;
												width:calc(100vw - 20px);
												display:none !important;
											}
										}
										&.Cal__Day__disabled {
											color:rgba(255, 255, 255, 0.35);
										}
										span {
											color: $genric-color-w;
										}
										
										&::before {
											display:none;
										}
										&.Cal__Day__selected {
											color: $genric-color-w !important;
											background-color: #05ad83;
											&.Cal__Day__betweenRange {
												background-color:#0ec99a;
											}
											.Cal__Day__selection {
												height: 50px;
												line-height: 50px;
												margin-top:-25px !important;
												background-color:transparent !important;
												display:none;
												.Cal__Day__month {
													display:none !important;
												}
											}
											&.Cal__Day__start,&.Cal__Day__end {
												&::after {
													display:none;
												}
												.Cal__Day__selection {
													@include border-radius(3px !important);
													background-color: #05ad83 !important;
													display:block;
													border:none;
													.Cal__Day__day {
														@include font-weight(medium);
														top:0;
														font-size:$font_15;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.floating-btn {
				background-color: $genric-color-w;
				color:$color-primary;
			}

			.modal-footer {
				height:59px;
				border-top:1px solid rgba(255, 255, 255, 0.2);
				.done-btn {
					@include headline($genric-color-w,$font_17);
					text-align:center;
					line-height:60px;
					display:block;
				}
			}
		}
	}

	.select-guests-modal {
		.modal-header {
			padding:25px 15px;
			height:65px;
			a {
				top:25px !important;
			}
			h2{
				position:absolute;
				top:0;
				left:0;
				width:100%;
			}
		}
		.modal-body {
			padding: 0 20px;
			h2 {
				@include headline($color-dark-grey-2, 26px);
				margin:3px 0 46px;
				line-height:31px;
				&.opacity-0{
					opacity:0;
					margin-bottom:20px;
				}
			}
			.date-picker {
				background-color: rgb(249,248,245);
				border:1px solid rgb(242, 242, 242);
				line-height:16px;
				padding:18px 0;
				@include border-radius(40px);
				@include headline($color-primary, $font_15);
				position:relative;
				margin-bottom:50px;
				span {
					width:50%;
					display:inline-block;
					text-align:center;
					padding:0 10px;
					font-size: 17px;
					color:rgb(133, 133, 133);
					font-weight: 500;
					div{
						margin-bottom: 6px;
						color: rgb(191, 191, 191);
						font-size:14px;
						font-weight: 500;
					}
				}
				&.shakeAnimation {
					animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
					transform: translate3d(0, 0, 0);
					backface-visibility: hidden;
					perspective: 1000px;
				}
				i {
					background-image:url($sprite-image);
					background-position:-822px -299px;
					width: 23px;
    				height: 21px;
					position:absolute;
					top:50%;
					left:50%;
					transform:rotate(180deg) translate(50%, 50%)

				}
			}
			.guest-count {
				display:inline-block;
				span {
					background-image:url($sprite-image);
					width: 47px;
					height: 47px;
					float: left;
					&.minus-btn {
						background-position: -113px -452px;
						&.disable {
							opacity:0.5;
							pointer-events:none;
						}
					}
					&.plus-btn {
						background-position: -166px -4px;
						&.disable {
							opacity:0.5;
							pointer-events:none;
						}
					}
				}
				.total-bedrooms {
					float: left;
					height: 47px;
					color: #00ba8c;
					font-size: 16px;
					width: 162px;
					line-height:47px;
				}
				&.shakeAnimation {
					animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
					transform: translate3d(0, 0, 0);
					backface-visibility: hidden;
					perspective: 1000px;
				}
			}
			@keyframes shake {
				10%, 90% {
					transform: translate3d(-1px, 0, 0);
				}

				20%, 80% {
					transform: translate3d(2px, 0, 0);
				}

				30%, 50%, 70% {
					transform: translate3d(-4px, 0, 0);
				}

				40%, 60% {
					transform: translate3d(4px, 0, 0);
				}
			}

		}
	    @media only screen and (max-width : 350px){ 
	    	.modal-body{
	    		padding:0 15px
	    	}
	    }
	    .floating-btn-box {
	    	bottom: 50px;
	    	@include transition(none);
		}
		@media screen and (max-height: 480px) {
			.floating-btn-box{
				bottom: 0;
			}
		}

	}
}
.collection-inner-section {
	position:relative;
.header-wraper{
	margin: 20px 0;
	width: 100%;
	float: left;
	position:relative;
	padding: 0 15px;
	z-index: 2;
	.title{
		display: -webkit-box;
		-webkit-line-clamp: 2;
		color: #858585;
		font-size: 17px;
		font-weight: 500;
	}
	.seeall{
		color: #00ba8c;
		font-size: 13px;
	}
}
}
@media only screen and (max-width : 350px){ 
	.outer-showcase{
		min-height:314px;
	}
	.trending-cities{
		.trending-item {
			h3 {
				font-size: $font_12 !important;
			}
		}
	}
}




