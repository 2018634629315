

.collection-container{
	margin: 70px 0 15px;
	.animated-background {
		height: 250px;
	}
	.padding-top{
		margin-top: 20px;
	}
	.heading{
		font-size: 20px;
		font-weight: 500;
		color: #00ba8c;
		margin-bottom:20px;
	}
	.collection-btn{
			width: 222px;
		    height: 50px;
		    border-radius: 100px;
		    background-color: #00ba8c;
		    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
		    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
		    line-height: 50px;
		    text-align: center;
		    margin:0 auto;
		    color:#fff;
		    font-size: 16px;
		}
	.collection-inner-section {
		&.margin-bottom{
			margin-bottom: 20px;
		}
		.mask{
			border-radius: 2px;
		    background-color: rgba(0, 0, 0, 0.4);
		    background-size: cover;
		    position: relative;
		    .innermask{
		    	position:absolute;
		    	width:100%;
		    	height:100%;
		    	top:0;
		    	left:0;
		    	text-align:center;
		    	background-color: rgba(0, 0, 0, 0.4);
		    	.text{
		    		position:absolute;
		    		top:50%;
		    		left:50%;
		    		font-size: 20px;
		    		padding:0 15px;
		    		width: 100%;
  					font-weight: 500;
		    		text-align:center;
		    		color:#fff;
		    		transform:translate(-50%,-50%);
		    	}
		    }
		}
		.swiper-container{
			margin-bottom: 20px;
		}
		.collection-item-tile {
			&.see-all-tile {
				height: calc(100% + 5px) !important;
				background: #7fdbc5;
				@include headline($genric-color-w, $font-18);
				@include font-weight(thin);
				margin-bottom: 0;
				span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}
			}

			.imgb {
				background-color: $color-light-grey-2;
				background-size: cover;
				background-position: top center;
				width: 100%;
				padding-top:67%;
				background-image:url(../img/search_placeholder.jpg);
				@include border-radius(2px);
				.wishlist-icon {
					background-image: url($sprite-image);
					position: absolute;
					width: 30px;
					height: 27px;
					top: 15px;
					right: 15px;
					background-position:-121px -12px;
					&.active {
						background-position:-67px -458px;
					}
				}
			}

			.description {
				background-color: $genric-color-w;
				padding:15px 15px 20px 15px;

				.row {
					margin:0;
				}

				h3 {
					@include headline($color-dark-grey, $font-16);
					@include font-weight(medium);
					margin: 0px 0px 5px;
					white-space: nowrap; 
				    overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
					display: inline-block;

					.dot-separation{
						position: relative;
						top: -4px;
						display: inline-block;
						margin: 0 5px;
						width: 2px;
						height: 2px;
						background: $color-dark-grey;
					}
				}

				.location {
					@include headline($color-dark-grey, $font-16);
					@include font-weight(thin);
					line-height:18px;
					white-space: nowrap; 
				    overflow: hidden;
					text-overflow: ellipsis;
					width: 100%;
					display: inline-block;
				}

				.price {
					@include headline(#838383, $font-20);
					margin-top: 7px;
					display: block;
					line-height:23px;
				}
				
				.rating {
					width: 32px;
					height: 32px;
					background-color: $color-green;
					@include headline($genric-color-w,$font-15);
					@include border-radius(50%);
					@include font-weight(bold);
					margin-top:0px;
					line-height:32px;
					text-align:center;
				}
	
			}
		}
	}
	& + .bottom-tabs-ul{
        display: none;
    }
}
.collection_list{
	& + .bottom-tabs-ul{
        display: none;
	}
	
	.animated-background {
		height: 250px !important;
	}
}

.collection-list-container{
	padding-top: 62px;
	.info-window {
		padding-top: 20px;
	}
	.banner{
		background-size:cover;
		width:100%;
		padding-top:100%;
		position:relative;
		margin-bottom: 25px;
		.innermask{
		    	position:absolute;
		    	width:100%;
		    	height:100%;
		    	top:0;
		    	left:0;
		    	text-align:center;
		    	background-color: rgba(0, 0, 0, 0.4);
		    	.text{
		    		position:absolute;
		    		top:50%;
		    		left:50%;
		    		font-size: 20px;
		    		padding:0 15px;
		    		width: 100%;
  					font-weight: 500;
		    		text-align:center;
		    		color:#fff;
		    		transform:translate(-50%,-50%);
		    	}
		    }
	}
	.collection-list{
		padding: 0 15px;
	}
	.header .middle-text{
		left: 50%;
		width:80%;
		white-space: nowrap; 
	    overflow: hidden;
	    text-overflow: ellipsis;
	}
}



