$color-primary: #00ba8c;
$color-light-grey: #f2f2f2;
$color-dark-grey: #858585;
$color-dark-grey-2: #7b7b7b;
$color-dark-green: #08a880;
$genric-color-w:#fff;
$genric-color-b:#000;
$color-light-grey: #ededed;
$color-light-grey-2: #d8d8d8;
$color-green: #7fd482;

$color-selected-tab: orange;
$sprite-image: '../img/common/common-sprite.svg';
$amenities-sprite: '../img/common/amenities-sprite.svg';


//common variables
$font-stack:'MuseoSans';

	// fonts
$font_9:9px;
$font_10:10px;
$font_11:11px;
$font_12:12px;
$font_13:13px;
$font_14:14px;
$font_15:15px;
$font_16:16px;
$font_17:17px;
$font_18:18px;
$font_20:20px;
$font_21:21px;
$font_22:22px;

	// color
$primary-color:#00ba8c;
$text-color:#858585;
$heading-color:#7b7b7b;