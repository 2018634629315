.search-location-modal{
	background:#fff;
	.modal-header {
		background-color: $color-primary;
		padding:12px 15px 0;
		height:68px;
		& > div {
			height:calc(100vh - 125px);
			overflow-y:scroll;
			margin-top:56px;
			padding: 0 15px;
		}
		.location-icon{	
			position: absolute;
			left: 25px;
			top: 26px;
			background-image:url($sprite-image);
			width: 12px;
			height: 17px;
			background-position: -21px -19px;
			z-index: 9;
		}
		input{
            background-color: $color-dark-green;
            @include border-radius(4px);
            height: 44px;
            padding: 12px 12px 12px 36px;
            @include headline($genric-color-w, $font-13);
            @include font-weight(medium);
            margin:0 0 12px;
            width:79% !important;
            transform: translate3d(0,0,0);
            position:fixed;
            top:12px;
            &::-webkit-input-placeholder {
            	color:rgba(255,255,255,0.8) !important;
            }
		}
		span{
			padding:15px 0 !important;
			&.reset-btn {
				@include headline($genric-color-w !important,$font-13 !important);
				height:44px;
				top:10px !important;
				right: 15px !important;
			}
		}
	
		.form-control {
			width:100%;
		}
		.my-autocomplete-container {
			border:none !important;
			width:100% !important;
			padding:0 15px !important;

			div {
				width:100% !important;
				padding:0 !important;
			}
			.location-item {
				padding: 14px 0 !important;
			}
		}
		form + div {
			padding:0 15px;
		}
		.location-item {
			border-bottom:1px solid #f2f2f2 !important;
			padding: 18px 0 !important;
			margin:0;
			position:relative;
			width:100%;
			.date {
				@include headline(#9f9f9f, $font-12);
				line-height:15px;
				right:0;
				top:2px;
			}
			.trending-icon {
                background-image: url($sprite-image);
                background-position: -124px -392px;
				width:23px;
				height:23px;
				right:0;
				top:50%;
				@include translateY(-50%);
				padding:0;
			}
			strong {
				@include headline($color-dark-grey-2, $font-14);
				@include font-weight(bold);
				line-height:15px;
				line-height:16px;
				padding-top:2px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 70%;
				display: inline-block;
				small {
					@include headline(#9f9f9f, $font-14);
					
				}
			}
			
		}
	}
	
	#PlacesAutocomplete__google-logo {
		text-align:center !important;
		background:transparent !important;
		position:fixed;
		bottom:10px;
		left:0;
		width:100%;
		img {
			width:88px !important;
		}
	}

	@media screen and (max-width:360px) {
		.modal-header {
			input {
				width: 75% !important;
			}
			.location-item{
				strong {
					width: 64%;
				}
			}
		}
	}

}