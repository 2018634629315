
$grey:#9b9b9b;
.booking-container {
    margin-top:73px;
    padding-bottom: 50px;

    .button{
        height: 35px;
        border-radius: 100px;
        line-height: 35px;
        margin: 0 auto;
        margin-top: 30px;
        border: none;
        outline: none;
        display: block;
        width: 208px;
        background-color: #a7a7a7;
        box-shadow: 0 2px 12px 0 rgba(114, 114, 114, 0.2);
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
    }
}
.booking{
    margin: 15px;
    position: relative;
}
.booking-tile{
    width: 100%;
    border-radius:5px;
    background: $genric-color-w;
    margin-bottom: 10px;
    padding: 15px;
    .response{
        padding-bottom: 10px;
        border-bottom: 1px solid  #f2f2f2;
        .timeline-text{
            font-size: 12px;
            font-weight: 500;
            color: #c4c4c4;
            .ongoing-blink{
                width:20px;
                height:20px;
                background-image:url('../img/blink-3.gif');
                background-repeat:no-repeat;
                display: inline-block;
                text-align: right;
                background-size: 20px;
            }
        }
        .text{
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            &.upcoming{
                color: #00ba8c;
            }
            &.ongoing{
                color: #00ba8c;
            }
            &.completed{
                color: #989898;
            }
            &.awaiting{
                color: #d8b215;
            }
            &.approved{
                color: #00ba8c;
            }
            &.canceled{
                color: #f2a419;
            }
            &.expired{
                color:#c4c4c4; 
            }
            &.rejected{
                color: #f26565;
            }
        } 
        .icon{
            width: 22px;
            height: 20px;
            background: url(../img/common/common-sprite.svg);
            margin-right: 0;
            &.awaiting{
                background-position: -124px -709px;
            }
            &.approved{
                background-position: -124px -756px;
            }
            &.expired{
                color: #c4c4c4;
            }

        }
    }
    .tile{
        padding-top: 15px;
        width: 100%;
        .heading{
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            margin-left: 18px;
            color: #7b7b7b;
            max-height: 42px;
            line-height: 18px;
            display: -webkit-box;
            display: -moz-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            p{
                &::first-letter {
                    text-transform: uppercase !important;
                }
            }
        }
        .subheading{
            font-size: 13px;
            margin-top: 3px;
            font-weight: 500;
            margin-left: 18px;
            text-align: left;
            color: #858585;
        }
        .price{
            font-size: 15px;
            margin-top: 12px;
            margin-left: 18px;
            font-weight: 500;
            color: #858585;
        }
        .property-image{
            background-image:url(../img/trip-list-placeholder.jpg);
            background-size: cover;
            padding-top:80%;
            width: 105%;
            border-radius: 3px;
            background-repeat: no-repeat;
            min-height: 85px;
        }
    }

}
.booking-details{
    .no-internet{
        margin-top: 70px;
    }
    .approved-text{
        color:rgb(133, 133, 133);
        font-size:14px;
        padding:25px;
        text-align:center; 
    }
    .cancelled-btn{
        background: url(../img/common.svg);
        height: 25px;
        width: 25px;
        background-position: -42px -929px;
        position: absolute;
        right: -5px;
    }
    .payment-response {
        text-align: center;
        padding-top:45px; 
        height: 190px;
        .image{
            width: 100px;
            height: 55px;
            margin: auto;
            background: url(../img/common/common-sprite.svg);
            &.expired{
                background-position: -450px -615px;
            }
            &.approved{
                background-position: -450px -615px;
            }
            &.canceled{
                background-position: -445px -520px;
                width:110px;
            }
            &.rejected{
                background-position: -445px -724px;
                width:110px;
            }
        }
        .timer{
            height:30px;
            font-size: 25px;
            font-weight: 300;
            text-align: center;
            color: #ffffff;
            margin-bottom:30px;
        }
        .text{
            margin-top:20px;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            color:$genric-color-w; 
            padding: 0 18px;
        }
        &.awaiting{
            background-color: #d8b215;
        }
        &.expired{
            background-color:#c4c4c4; 
        }
        &.canceled{
            background-color: #f2a419;
        }
        &.not-avalable{
             background-color: #f26565;
        }
        &.approved{
             background-color: #00ba8c;
        }
        &.rejected{
             background-color:  #f26565;
        }
    }
    .request-wrap{
        padding: 15px;
        background: $genric-color-w;
        .booking-date {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #858585;
        }
        .modify-btn {
            font-size: 12px;
            font-weight: 500;
            margin-top: 20px;
            text-align: right;
            color: #00ba8c;
            span.arrow-icon {
                width: 15px;
                height: 11px;
                background: url(../img/common/common-sprite.svg);
                background-position: -191px -817px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .invoice{
        .requestID{
            font-size: 13px;
            font-weight: 500;
            text-align: right;
            display: block;
            color: #a7a7a7;
            margin-right: 15px;
        }
    }
    .button{
        width: 290px;
        height: 54px;
        margin: 40px auto 0px; 
        text-align: center;
        line-height: 54px;
        border: none;
        outline:none;
        color: $genric-color-w;
        font-size: 17px;
        font-weight: bold;
        border-radius: 100px;
        background-color: #00ba8c;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    }
    & + .bottom-tabs-ul{
        display: none;
    } 
    
}
.property-bottom {
        position:fixed;
        bottom:0;
        left:0;
        background-color:$genric-color-w;
        width:100%;
        height:64px;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
        z-index: 100;
        padding:10px 15px;
        .btn {
            font-size:$font_15;
            &:hover {
                background-color:$color-primary;
            }
        }
        h5 {
            @include headline($color-dark-grey-2,$font_16);
            line-height:18px;
            margin:4px 0;
            span {
                @include headline($color-primary,$font_12);
                display: block;
            }
        }
        .reserve-icon{
            display: inline-block;
            height: 20px;
            width: 14px;
            vertical-align: middle;
            background: url('../img/common/common-sprite.svg');
            background-position: -400px -183px;
            margin-right: 6px;
        }
    }
.invoice{
    margin-top: 30px;
    .heading{
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: $heading-color;
        .invoice-icon{
            width: 28px;
            height: 24px;
            margin-left: 15px;
            background: url(../img/common/common-sprite.svg);
            background-position: -398px -467px;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .invoice-details{
        background: $genric-color-w ;
        padding: 25px 15px;
        margin-top: 15px;
        .first{
            border-bottom:1px solid #f2f2f2;
        }
        .second{
            margin-top:20px; 
        }
        .cell{
            margin-bottom: 20px;
            .title{
                font-size: 15px;
                font-weight: 500;
                text-align: left;
                color: $grey;
            }
            .subtitle{
                font-size: 13px;
                font-weight: 500;
                text-align: left;
                color: $grey;
            }
            .cost{
                font-size: 15px;
                font-weight: 500;
                text-align: right;
                color: $grey;
            } 
        }
        .discount{
            .green-color{
                color: $primary-color
            }
        }
        .booking-amount{
            margin-top: 5px;
            margin-bottom:14px; 
            .title, .cost{
                font-size: 16px;
                font-weight: bold;
                color: $heading-color;
            }
        }
        .payable{
            margin-bottom:15px; 
            .title, .cost{
                font-size: 14px;
                font-weight: 500;
                color: #9b9b9b;
            }
        }
        .payable:last-child{
            margin-bottom: 0;
        }
    }
    
}
.cancel-by-user{
    margin-top: 30px;
    .heading{
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: $heading-color;
        .icon{
            width: 28px;
            height: 24px;
            margin-left: 15px;
            background: url(../img/common/common-sprite.svg);          
            display: inline-block;
            vertical-align: middle;
            &.request-details{
                background-position: -186px -528px;
            }
        }
    }
    .box{
        background: $genric-color-w;
        padding: 25px 15px 0 15px;
        margin-top: 16px;
        .wrap{
            margin-bottom:30px; 
            .title{
                font-size: 15px;
                font-weight: 500;
                text-align: left;
                color: #7b7b7b;
            }
            .subtitle{
                font-size: 13px;
                font-weight: 500;
                text-align: left;
                color: #858585;
            }
        }
        .icon{
            float: left;
            height: 26px;
            &.request-details{
                 background-position: -186px -568px; 
            }
            &.guest{
                background-position: -186px -617px;
            }
            &.checkin{
                background-position: -186px -668px;
            }
            &.checkout{
                background-position: -188px -721px;
            }
        }
    }
    
}

.archivedlsit{
    margin-top: 73px;
    position: relative;
    


.response{
        border-bottom: 1px solid  #f2f2f2;
        .text{
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            &.awaiting{
                color: #d8b215;
            }
            &.approved{
                color: #00ba8c;
            }
            &.canceled{
                color: #f2a419; 
            }
            &.expired{
                color:#c4c4c4; 
            }
            &.rejected{
                color: #f26565;
            }
        } 
        .icon{
            width: 22px;
            height: 20px;
            background: url(../img/common/common-sprite.svg);
            margin-right: 0;
            &.awaiting{
                background-position: -124px -709px;
            }
            &.approved{
                background-position: -124px -756px;
            }
            &.expired{
                color: #c4c4c4;
            }

        }
    }
    & + .bottom-tabs-ul{
        display: none;
    }
}
.editProfile.request-modal{
    .verify-text1{
        font-size: 20px !important;
        font-weight: 500 !important;
        margin-bottom:20px;
    }
    .text{
        font-size: 15px;
        margin-bottom:20px;
        color: #7b7b7b;
    }
    .text-cacel{
        font-size: 15px;
    }
    button{
        width: 246px !important;
    }
    
}
