
@import "../components/_property-tile.scss";
@import "../layouts/modal/_search-filter.scss";

.header {
	.modify-btn {
		float:right;
		i {
			background-image:url(../img/common/review-sprite.svg);
			width:40px;
			height:41px;
			display:block;
			&.add-icon {
				background-position: -37px -418px;
			}
			&.modify-icon {
				background-position: -36px -337px;
			}
		}
	}
	h1 {
		@include headline($genric-color-w, $font_17);
		margin:0;
		line-height:19px;
		@include font-weight(bold);
		padding-left:10px;
				display:inline-block;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				width:calc(100% - 25px);

		span {
			font-size:$font_12;
			display:block;
						line-height:14px;
						margin-top:5px;
		}
		&.only-location {
			margin:0;
						padding: 10px 0 9px 7px;
		 }
	}
		@media only screen and (max-width : 350px){  
				h3 {
						padding-left:0px !important;
				}
		}
}
.search-result-section {
	padding:72px 15px 0;
}
.search-page {
	.no-result {
		text-align:center;
		padding-top:37px;
		img {
			margin-bottom:10px;
		}
		p {
			font-size:$font_14;
		}

		&.true {
			display:none;
		}
	}
	.loader {
				overflow:hidden;
				height:calc(100vh - 132px);
		&.false {
			display:none;
		}
	}
	.info-window {
		margin-top: 70px;
	}
}
.sticky-button {
	background:#fff;
	position:fixed;
	bottom:80px;
	@include border-radius(40px);
	overflow:hidden;
	left:50%;
	transform:translate(-50%,0);
	-webkit-transform:translate(-50%,0);
	-moz-transform:translate(-50%,0);
	box-shadow: 0 2px 12px 0 rgba(114, 114, 114, 0.5);
	z-index:99;
	span,button {
		float:left;
		height:44px;
		padding:12px 24px;
		line-height:22px;
		@include headline($color-dark-grey-2, $font_15);
				@include font-weight(medium);
				background:$genric-color-w !important;
		i {
			background-image:url($sprite-image);
			background-position:-180px -341px;
			width:21px;
			height:21px;
			float:left;
			margin-right:12px;
		}
				&.filter.active {
						color:$color_primary;
						i{
								background-position:-180px -395px;
						}
				}
	}
}
@-webkit-keyframes placeHolderShimmer {
	0% {
	background-position: -468px 0; }
	100% {
	background-position: 468px 0; } 
}
@keyframes placeHolderShimmer {
	0% {
	background-position: -468px 0; }
	100% {
	background-position: 468px 0; } 
}
.timeline-item {
	background: #fff;
	border-radius: 3px;
	padding: 10px 10px 20px;
	margin: 0 auto;
	width: 100%;
	min-height: 272px; 
	margin-bottom:20px;
}

.animated-background {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-name: placeHolderShimmer;
	animation-name: placeHolderShimmer;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: #eeeeee;
	background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
	background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	-webkit-background-size: 800px 104px;
	background-size: 800px 104px;
	height: 272px;
	position: relative; 
}

.background-masker {
	background: #fff;
	position: absolute;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box; 
}

.background-masker.after-image {
	left: 0;
	top: 207px;
	height: 15px;
	width: 100%; 
}

.background-masker.after-heading {
	left: 0;
	top: 242px;
	height: 10px;
	width: 100%; 
}

.background-masker.after-heading-right {
	right: 0;
	top: 222px;
	height: 20px;
	width: 132px; 
}

.background-masker.after-subheading-right {
	right: 0;
	top: 252px;
	height: 20px;
	width: 70px; 
}

.hide-amenity-li{
	display : none !important;
}

.rheostat {
	overflow: visible;
}

.rheostat-background {
	background-color: #f1f0ec;
	position: relative;
	height:13px;
	@include border-radius(6px);
	-webkit-box-shadow: inset 0 1px 3px 0 rgba(203, 203, 203, 0.5);
	box-shadow: inset 0 1px 3px 0 rgba(203, 203, 203, 0.5);
}

.rheostat-progress {
	background-color: $color_primary;
	position: absolute;
	top:0;
	height:13px;
}

.rheostat-handle {
	background-color: $genric-color-w;
	background-image:url($sprite-image);
	background-position: -176px -460px;  
	width: 31px;
	height: 31px;
	top: -10px;
	z-index: 2;
	margin-left: -16px;
	border-width: 1px !important;
	border-radius: 32px !important;
	outline: none !important;
	border:none !important;
}

.rheostat-handle:before,
.rheostat-handle:after{
	content: '';
	display: block;
	position: absolute;
	background-color: #dadfe8;
}


.inline-filter {
	background-color:$color_primary;
	padding:25px 15px;
	width:calc(100% + 30px);
	margin:0 0 10px -15px;
	display:block;
	h2{
		@include headline($genric-color-w, $font_20);
		@include font-weight(bold);
		margin:0 0 12px;
		line-height:24px;
	}
	p{
		@include headline($genric-color-w, $font_15);
		@include font-weight(medium);
		line-height:20px;
		margin-bottom:20px;
	}
	button{
		background:#2d9c7e;
		line-height:50px;
		height:50px;
		@include border-radius(25px);
	}
	.no-results {
    height: 44px;
    border-radius: 22px;
    padding: 0 32px;
    color: #fff;
    line-height: 44px;
    box-shadow: none;
    text-transform: capitalize;
    border: none;
	}
}


// promo banner
.promo-banner {
	img{
		width:100%;
	}
}


// stay page
.stay-page-content{
	h2 {
		margin:15px 0 8px;
		@include headline($color-dark-grey-2, $font_18);
		@include font-weight(bold);
		line-height:30px;
		&.popup-content-title {
			margin:8px 0;
			line-height:20px;
			color:$color_primary;
			@include font-weight(medium);
		}
	}
	p,.popup-content{
		line-height:20px;
		font-size:$font_15;
		padding-bottom:20px;
		a{
			@include font-weight(bold);
			color:$color_primary;
		}
	}
	.stay-page-description{
		height: 103px;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 5;
		display: -webkit-box;
		position: relative;

		&.active {
			height: auto;
			display: block;
		}
		&:after{
			content: '';
			position:absolute;
			width: 100%;
			height: 30px;
			bottom: 2px;
			left: 0;
			background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(250,249,246,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(250,249,246,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(250,249,246,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#faf9f6',GradientType=0 ); /* IE6-9 */

		}
	}
	
	.read-more{
		display: block;
		text-align: center;
		padding: 5px 0 20px;
		@include headline($color_primary, $font_14);
		@include font-weight(bold);
	}

}

.stays-link{
	margin-bottom:35px;
	&.last{
		margin-bottom:70px;
	}
	h3{
		margin:0 0 15px;
		@include headline($color-dark-grey-2, $font_18);
		@include font-weight(bold);
		line-height:30px;
	}
	ul{
		background-color:$genric-color-w;
		float:left;
		overflow:hidden;
		height: auto;
		width: 100%;
		min-height: 56px;
		max-height: 168px;
		&.expand{
			max-height:100%;
		}
		li{
			width:50%;
			float:left;
			border-bottom:1px solid #f9f9f9;
			&:nth-child(even){
				border-left:1px solid #f9f9f9;
			}
			div{
				color:#b2b1b1;
				height:55px;
				line-height:55px;
				text-align:center;
				display:block;
				outline:none;
				&.selected {
					color:$color_primary;
				}
			}
		}
	}
	.read-more {
		background-color:$genric-color-w;
		height:44px;
		line-height:44px;
		text-align:center;
		color:$color_primary;
		clear:both;
		@include font-weight(normal);
	}
}

.nav-control{
	margin-bottom:20px;
	span{
		background-color:$genric-color-w;
		display:inline-block;
		height:44px;
		line-height:45px;
		padding: 0 15px;
		@include headline($color_primary, $font_14);
		@include font-weight(bold);
		@include border-radius(3px);
		i{
			border: solid $color_primary;
			border-width: 0 2px 2px 0;
			display: inline-block;
			padding: 1px;
			background: none;
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
			height: 8px;
			width: 8px;
			top: -1px;
			position: relative;
		}
		&.prev {
			i{
				-ms-transform: rotate(135deg);
				transform: rotate(135deg);
				-webkit-transform: rotate(135deg);
			}
		}
	}
}
ul.breadcrumb {
    padding: 10px 15px;
    list-style: none;
    background-color: $genric-color-w;
    margin:-10px -15px 0;
}
ul.breadcrumb li {
    display: inline;
    font-size: $font_13;
    color:$color-dark-grey;
}
ul.breadcrumb li+li:before {
	background-image:url(../img/breadcrumb-arrow.png);
	background-repeat:no-repeat;
	background-position:center center;
    padding: 8px;
    color: black;
    content: "";
}
ul.breadcrumb li a {
    color: $color_primary;
    text-decoration: none;
}
ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
}
#read_more_location_content, .scroll_to_top{
	display:none;
}
// #readMore,.goToTop{
// 	color:#00ba8c;
// 	font-size:$font_15;
// 	display:block;
// 	padding:10px 0;
// }
// .stay-page-content p:nth-child(2){
// 	padding-bottom:10px;
// }