/* fonts */
@font-face{
    font-family:'MuseoSans';
    src:url('../fonts/museosans_100-webfont.eot');
    src:url('../fonts/museosans_100-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museosans_100-webfont.woff') format('woff'),
        url('../fonts/museosans_100-webfont.ttf') format('truetype');
    font-weight:100;
    font-style:normal;
}
@font-face{
    font-family:'MuseoSans';
    src:url('../fonts/museosans_300-webfont.eot');
    src:url('../fonts/museosans_300-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museosans_300-webfont.woff') format('woff'),
        url('../fonts/museosans_300-webfont.ttf') format('truetype');
    font-weight:300;
    font-style:normal
}
@font-face{
    font-family:'MuseoSans';
    src:url('../fonts/museosans_500-webfont.eot');
    src:url('../fonts/museosans_500-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museosans_500-webfont.woff') format('woff'),
        url('../fonts/museosans_500-webfont.ttf') format('truetype');
    font-weight:500;
    font-style:normal;
}
@font-face{
    font-family:'MuseoSans';
    src:url('../fonts/museosans_700-webfont.eot');
    src:url('../fonts/museosans_700-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museosans_700-webfont.woff') format('woff'),
        url('../fonts/museosans_700-webfont.ttf') format('truetype');
    font-weight:700;
    font-style:normal;
}

$font-medium: 1rem;
$font-small-1: $font-medium * 0.7;
$font-small-2: $font-medium * 0.8;
$font-small-3: $font-medium * 0.9;
$font-large-1: $font-medium * 1.1;
$font-large-2: $font-medium * 1.2;
$font-large-3: $font-medium * 1.3;