
$grey:#9b9b9b;
.row{
	margin-bottom: 0;
}
.prepayment{
	& + .bottom-tabs-ul{
		display: none;
	}
	.info-window{
		margin-top:70px;
	}
}
.booking-property-details{
	padding: 15px;
	background: $genric-color-w;
	margin-top: 70px;
	.property-image{
		background-size: cover;
		padding-top: 70%;
		width: 80%;
		border-radius: 3px;
		background-repeat: no-repeat;
	}
	.heading{
		line-height: 24px;
		margin-left: -10px;
		text-overflow: ellipsis; 
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.subheading{
		margin-left: -10px;
		font-size: 13px;
		font-weight: 300;
		color: #858585;
		padding-top:2px;
		text-overflow: ellipsis; 
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.booking-date{
		font-size: 14px;
		font-weight: 500;
		text-align: left;
		margin-top: 15px;
		color: $color-dark-grey;
	}
	.booking-details{
		font-size: 12px;
		font-weight: 500;
		text-align: left;
		color: $color-dark-grey;
	}
	.modify-btn{
		margin-top: 15px;
		text-align: right;
		color: $primary-color;
		font-weight: 500;
		span.arrow-icon{
			width: 10px;
			height: 10px;
			background: url(../img/common/common-sprite.svg);
			background-position: -196px -817px;
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.invoice{
	margin-top: 26px;
	.heading{
		font-size: 16px;
		font-weight: 500;
		text-align: left;
		color: $heading-color;
		.invoice-icon{
			width: 28px;
			height: 24px;
			margin-left: 15px;
			background: url(../img/common/common-sprite.svg);
			background-position: -398px -467px;
			display: inline-block;
			vertical-align: middle;
		}
	}
	.invoice-details{
		background: $genric-color-w;
		padding: 25px 15px;
		margin-top: 15px;
		.first{
			border-bottom:1px solid #f2f2f2;
		}
		.second{
			margin-top:20px; 
		}
		.cell{
			margin-bottom: 20px;
			.title{
				font-size: 15px;
				font-weight: 500;
				text-align: left;
				color: $grey;
			}
			.subtitle{
				font-size: 13px;
				font-weight: 500;
				text-align: left;
				color: $grey;
			}
			.cost{
				font-size: 15px;
				font-weight: 500;
				text-align: right;
				color: $grey;
			} 
		}
		.discount{
			.green-color{
				color: $primary-color
			}
		}
		.third{
			margin-top: 5px;
			clear: both;
			.payable{
				.green-color{
					color: $primary-color;
					font-size: 12px;
				}
			}
		}
		// .cell1:first-child{
		//     margin-top: 25px;
		// }
		// .cell1{
		//     .booking-amount{
		//         margin-top: 15px;
		//         margin-bottom: 0;
		//     .title, .cost{
		//         font-size: 15px;
		//         color: #9b9b9b;
		//         font-weight: 500;
		//     }
		//     .green-color{
		//         color: $primary-color;
		//         font-size: 12px;
		//     }
		// }
		// }
		
		// .payable{
		//     margin-bottom:15px; 
		//     .title, .cost{
		//         font-size: 14px;
		//         font-weight: 500;
		//         color: #9b9b9b;
		//     }
			
		// }
		// .payable:last-child{
		//     margin-bottom: 0;
		// }
	}
	.requestID{
		display: none;
	}
}

.wallet-coupon{
	 margin-top: 26px;
	.heading{
		font-size: 16px;
		font-weight: 500;
		text-align: left;
		margin-left: 15px;
		color: $heading-color;
	}
	.check-box-wrap{
		background: $genric-color-w;
		padding: 25px 15px;
		margin-top: 15px;
		.box{
			margin-bottom: 20px;
		}
		.box:last-child{
			margin-bottom: 0;
		}
	}
	.wallet-icon{
		width: 23px;
		height: 24px;
		background: url(../img/common/common-sprite.svg);
		background-position: -453px -130px;
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
	}
	.promo-code{
		width: 23px;
		height: 24px;
		background: url(../img/common/common-sprite.svg);
		background-position: -453px -182px;
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
	}
	.text{
		font-size: 14px;
		font-weight: 500;
		text-align: left;
		color: $heading-color;
	}
	input:before, input:after{
		display: none !important;
	}
	input[type=radio].css-checkbox {
		position:absolute; 
		z-index:-1000; 
		left:-1000px; 
		overflow: hidden; clip: rect(0 0 0 0); 
		height:1px; 
		width:1px; 
		margin:-1px;
		padding:0; 
		border:0;
		-webkit-transition: none;
		-moz-transition: none;
		transition: none;
		&:before{
			display: none !important;
		}
		&:after{
			display: none !important;
		}
	}
	input[type=radio].css-checkbox + label.css-label {
		padding-left:26px;
		height:25px; 
		display:inline-block;
		line-height:21px;
		background-repeat:no-repeat;
		background-position: -450px -20px;
		font-size:21px;
		vertical-align:middle;
		cursor:pointer;
		-webkit-transition: none;
		-moz-transition: none;
		transition: none;
		&:before{
			display: none !important;
		}
		&:after{
			display: none !important;
		}

	}
	input[type=radio].css-checkbox:checked + label.css-label {
		background-position: -450px -74px;
	}
	label.css-label {
		background-image:url(../img/common/common-sprite.svg);
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		&.disable{
			opacity: 0.5
		}
	}
	.success-msg, .error-msg{
		display: block;
		line-height: 14px;
		margin-left: 33px;
	}
}

.paymentSection{
	 margin-top: 26px;
	.heading{
		font-size: 16px;
		font-weight: 500;
		text-align: left;
		margin-left: 15px;
		color: $heading-color;
	}
	.choose-payment-mathod{
		margin-top: 20px;
		padding: 20px 15px;
		background: $genric-color-w;
	}
	.box{
		margin-bottom: 20px;
		position: relative;
		&.box:last-child{
			margin-bottom: 0;
		}
		span{
			display: inline-block;
			vertical-align: middle;
			&.icon{
				width:51px;
				height: 50px;
				display: inline-block;
				&.full_payment{
					background-position: -619px -122px;
				}
				&.partial_payment{
					background-position: -621px -364px;;
				}
				&.si_payment{
					background-position: -619px -192px;
				}
				&.coa_payment{
					background-position: -619px -1127px;
				}               
			}
			&.text{
				width: 65%;
				.title{
					font-size: 14px;
					font-weight: 500;
					text-align: left;
					color: $heading-color;    
				}
				.subtitle{
					font-size: 11px;
					font-weight: 500;
					text-align: left;
					color: $text-color;
				}
			}
		}
		.checkbox{
			position: absolute;
			right: 0;
			top:50%;
			transform:translateY(-50%);
			-webkit-transform:translateY(-50%);
			-moz-transform:translateY(-50%);
			input[type=radio].css-checkbox + label.css-label {
				padding-left:26px;
				height:25px; 
				display:inline-block;
				line-height:21px;
				background-repeat:no-repeat;
				background-position: -450px -20px;
				font-size:21px;
				vertical-align:middle;
				cursor:pointer;
				-webkit-transition: none;
				-moz-transition: none;
				transition: none;
				&:before{
					display: none !important;
				}
				&:after{
					display: none !important;
				}

			}
			input[type=radio].css-checkbox:checked + label.css-label {
				background-position: -450px -74px;
			}
			label.css-label {
				background-image:url(../img/common/common-sprite.svg);
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			input[type=radio]{
				opacity:0;
			}
		}
	}
	
}
.extra-feature {
		background-color:$genric-color-w;
		@include border-radius(3px);
		padding: 10px 0;
		margin:20px 15px;
		&.full-length{
			div {
				border: none !important;
			}
		}
		div {
			padding:0 10px;
			&:first-child {
				border-right:1px solid #d2d2d2;
			}
		}
		h4 {
			@include headline($color-primary, $font_13);
			margin: 0 0 6px;
			line-height:15px;
		}
		p {
			@include headline($color-dark-grey, $font_11);
			line-height:15px;
		}
	}

.term{
	margin:20px 15px;
	padding-bottom: 15px;
	.request-icon{
		display:inline-block;
		background-position: -462px -227px;
		background-image:url(../img/common/common-sprite.svg);
		width: 48px;
		vertical-align:middle;
		height: 48px;
	}
	input[type=checkbox].css-checkbox + label.css-label {
		padding-left:26px;
		height:25px; 
		display:inline-block;
		line-height:21px;
		background-repeat:no-repeat;
		background-position: -450px -20px;
		font-size:21px;
		vertical-align:middle;
		cursor:pointer;
		-webkit-transition: none;
		-moz-transition: none;
		transition: none;
		&:before{
			display: none !important;
		}
		&:after{
			display: none !important;
		}

	}
	input[type=checkbox].css-checkbox:checked + label.css-label {
		background-position: -450px -74px;
	}
	label.css-label {
		background-image:url(../img/common/common-sprite.svg);
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.icon{
		display: inline-block;
		vertical-align: middle;
	}
	.text{
		display: inline-block;
		display: inline-block;
		width: 85%;
		font-size: 13px;
		margin-left: 10px;
		font-weight: 500;
		text-align: left;
		line-height: 18px;
		color: #858585;
		vertical-align: middle; 
		span{
			display: block;
		}
		&.booking-avail{
			font-size: 13px;
			font-weight: 500;
			width:80%;
			vertical-align: middle;
			color: #858585;
		} 
		.green-color{
			color: $primary-color;
		}
	}
	&.book{
		margin-top:30px;
	}
}
.success-msg{
	font-size: 12px;
	font-weight: 500;
	text-align: left;
	color: #00ba8c;
}
.error-msg{
	font-size: 12px;
	font-weight: 500;
	text-align: left;
	color: #f65f5f;
	
}


.pay-btn{
	background-color: #ffffff;
	box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
	font-family: 'MuseoSans' !important;
	z-index: 9;
	position: fixed;
	padding:12px 15px;
	width: 100%;
	height: 65px;
	bottom: 0px;
	.loading {
		background-image:url('../img/loading.gif');
		background-position:-20px center;
		background-repeat:no-repeat;
		height:44px;
	}
	.avail-btn{
		width: 290px;
		height:42px;
		line-height:42px;
	}
	.text{
		font-size: 15px;
		font-weight: 500;
		text-align: left;
		color: #858585;

		.price{
			font-size: 14px;
			font-weight: 500;
			text-align: left;
			color: #00ba8c;
			}
	}
	.pay-button{
		height: 44px;
		font-size: 15px;
		padding: 0 30px;
		font-weight: bold;
		line-height: 44px;
		border-radius: 100px;
		border: none;
		outline: none;
		background-color: #00ba8c;
		color: $genric-color-w;
		.reserve-icon{
			display: inline-block;
			height: 20px;
			width: 14px;
			vertical-align: middle;
			background: url('../img/common/common-sprite.svg');
			background-position: -400px -183px;
			margin-right: 6px;
		}
		&.disable{
			background-image:url('../img/loading.gif');
			background-repeat:no-repeat;
			background-position:center;
			pointer-events:none;
			background-color:#fff;
			border:1px solid #00ba8c;
			color:transparent;
			.reserve-icon {
				opacity:0;
			}
		}

	}
	.col-12.text-center{
		.pay-button{
			width:100%;
			&.disable {
				background-position:center center;
			}
		}
	}
}

.coupon_input{
	border-radius: 3px !important;
	margin-top: 35px !important;
	padding:0 0 0 12px !important;
	background-color: #f9f8f5 !important;
	border: solid 1px #e7e7e7!important;
	// text-transform: uppercase;
	height: 44px !important;
	font-size:$font_13 !important;
	width:220px;
}
.coupon_input::-webkit-input-placeholder {
	text-transform: none;
}
.promo-code{
	.modal-body {
		padding:25px !important;
		.link{
			&.green{
				&.disable{
					background-color:transparent !important;
					color:#94dbc7;
					border: 1px solid #94dbc7;
					pointer-events: none;
					box-shadow: none;
					opacity: 0.5;
				}
			}
		}
	}
}

.editProfile {
	&.request-modal {
		.proceed-btn{
			margin-top:20px !important;
		}
	}
} 






















