
.filter-modal {

  h3 {
	@include headline($color-dark-grey-2,$font_18);
	@include font-weight(medium);
	margin:0;
  }
  .modal-body {
	padding:0 15px 60px;
	position: fixed;
	.price-range-filter {
	  padding-top:23px;
	  .price_range_slider {
		margin:27px 0 35px;
		padding:0 16px;
		.noUi-target,
		.noUi-target * {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-ms-touch-action: none;
		  touch-action: none;
		-ms-user-select: none;
		-moz-user-select: none;
		-moz-box-sizing: border-box;
		  box-sizing: border-box;
		}
		.noUi-target {
		  position: relative;
		  direction: ltr;
		}
		.noUi-base {
		  width: 100%;
		  height: 100%;
		  position: relative;
		  z-index: 1; /* Fix 401 */
		}
		.noUi-origin {
		  position: absolute;
		  right: 0;
		  top: 0;
		  left: 0;
		  bottom: 0;
		  background-color:$color-primary;
		  border-radius: 6px !important;
		  & + .noUi-origin {
			  background-color: #efefef;
			  -webkit-box-shadow: inset 0 1px 3px 0 rgba(203, 203, 203, 0.5);
			  box-shadow: inset 0 1px 3px 0 rgba(203, 203, 203, 0.5);
			  border-radius: 6px !important;

		  }
		}
		.noUi-handle {
		  z-index: 1;
		}
		.noUi-stacking .noUi-handle {
		/* This class is applied to the lower origin when
		   its values is > 50%. */
		  z-index: 10;
		}
		.noUi-state-tap .noUi-origin {
		-webkit-transition: left 0.3s, top 0.3s;
		  transition: left 0.3s, top 0.3s;
		}
		.noUi-state-drag * {
		  cursor: inherit !important;
		}

		/* Painting and performance;
		 * Browsers can paint handles in their own layer.
		 */
		.noUi-base {
		  -webkit-transform: translate3d(0,0,0);
		  transform: translate3d(0,0,0);
		}

		/* Slider size and handle placement;
		 */
		.noUi-horizontal {
		  height: 13px;
		}
		.noUi-horizontal .noUi-handle {
		  background:url($sprite-image);
		  background-position:-176px -460px;
		  box-shadow:none;
		  border:none;
		  width: 31px;
		  height: 31px;
		  margin-left: -17px;
		  margin-top: -9px;
		  @include border-radius(50%);
		}

		/* Styling;
		 */
		.noUi-background {
		  background: #FAFAFA;
		  box-shadow: inset 0 1px 1px #f0f0f0;
		}
		.noUi-connect {
		  background: #3FB8AF;
		  box-shadow: inset 0 0 3px rgba(51,51,51,0.45);
		  -webkit-transition: background 450ms;
		  transition: background 450ms;
		}
		.noUi-origin {
		  border-radius: 2px;
		}
		.noUi-target {
		  border-radius: 6px;
		  background-color: #efefef;
		  box-shadow: inset 0 1px 3px 0 rgba(203, 203, 203, 0.5);
		}
		.noUi-target.noUi-connect {
		  box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
		}

		/* Handles and cursors;
		 */
		.noUi-draggable {
		  cursor: w-resize;
		}
		.noUi-vertical .noUi-draggable {
		  cursor: n-resize;
		}
		.noUi-handle {
		  border: 1px solid #D9D9D9;
		  border-radius: 3px;
		  background: #FFF;
		  cursor: default;
		  box-shadow: inset 0 0 1px #FFF,
				inset 0 1px 7px #EBEBEB,
				0 3px 6px -3px #BBB;
		}
		.noUi-active {
		  box-shadow: inset 0 0 1px #FFF,
				inset 0 1px 7px #DDD,
				0 3px 6px -3px #BBB;
		}

		/* Disabled state;
		 */
		[disabled].noUi-connect,
		[disabled] .noUi-connect {
		  background: #B8B8B8;
		}
		[disabled].noUi-origin,
		[disabled] .noUi-handle {
		  cursor: not-allowed;
		}

		.noUi-tooltip {
		  display: block;
		  position: absolute;
		  width: 100px;
		  @include headline($color-primary, $font_14);
		}

		.noUi-handle-lower .noUi-tooltip {
		  bottom: -32px;
		  left:-15px;
		  text-align:left;
		  position:fixed;
		}

		.noUi-handle-upper .noUi-tooltip {
		  bottom: -32px;
		  right:-15px;
		  text-align:right;
		}

		/* Pips
		 */
		.noUi-pips,
		.noUi-pips * {
		-moz-box-sizing: border-box;
		  box-sizing: border-box;
		}
		.noUi-pips {
		  position: absolute;
		  color: #999;
		}

		/* Values;
		 *
		 */
		.noUi-value {
		  width: 40px;
		  position: absolute;
		  text-align: center;
		}
		.noUi-value-sub {
		  color: #ccc;
		  font-size: 10px;
		}

		/* Markings;
		 *
		 */
		.noUi-marker {
		  position: absolute;
		  background: #CCC;
		}
		.noUi-marker-sub {
		  background: #AAA;
		}
		.noUi-marker-large {
		  background: #AAA;
		}

		/* Horizontal layout;
		 *
		 */
		.noUi-pips-horizontal {
		  padding: 10px 0;
		  height: 50px;
		  top: 100%;
		  left: 0;
		  width: 100%;
		}
		.noUi-value-horizontal {
		  margin-left: -20px;
		  padding-top: 20px;
		}
		.noUi-value-horizontal.noUi-value-sub {
		  padding-top: 15px;
		}

		.noUi-marker-horizontal.noUi-marker {
		  margin-left: -1px;
		  width: 2px;
		  height: 5px;
		}
		.noUi-marker-horizontal.noUi-marker-sub {
		  height: 10px;
		}
		.noUi-marker-horizontal.noUi-marker-large {
		  height: 15px;
		}

		/* Vertical layout;
		 *
		 */
		.noUi-pips-vertical {
		  padding: 0 10px;
		  height: 100%;
		  top: 0;
		  left: 100%;
		}
		.noUi-value-vertical {
		  width: 15px;
		  margin-left: 20px;
		  margin-top: -5px;
		}

		.noUi-marker-vertical.noUi-marker {
		  width: 5px;
		  height: 2px;
		  margin-top: -1px;
		}
		.noUi-marker-vertical.noUi-marker-sub {
		  width: 10px;
		}
		.noUi-marker-vertical.noUi-marker-large {
		  width: 15px;
		}
	  } 
	}
	.booking-type-filter {
	  text-align:center;
	  padding: 35px 0;
	  label {
		display:inline-block;
		font-size:0;
		input {
		  height:0;
		  width:0;
		  display:block;
		  visibility:hidden;
		}
		i {
		  background:url($sprite-image) no-repeat;
		  display:inline-block;
		  width:48px;
		  height:48px;
		  margin-bottom:10px;
		  &.instant-book-icon {
			background-position: -220px -2px;
		  }
		  &.pay-later-icon {
			background-position: -220px -110px;
		  }
		  &.entire-home-icon {
			background-position: -220px -219px;
		  }
		}
		input:checked + i {
		  &.instant-book-icon {
			background-position: -219px -57px;
		  }
		  &.pay-later-icon {
			background-position: -220px -164px;
		  }
		  &.entire-home-icon {
			background-position: -220px -275px;
		  }
		}
		span{
		  display:block;
		  @include headline($color-primary, $font_15);
		  line-height:16px;
		  @include font-weight(medium);
		}
	  }
	}
	.bedrooms-filter {
	  line-height:35px;
	  padding-bottom:40px;
	  margin-bottom:35px;
	  border-bottom:1px solid #f2f2f2;
	  h3 {
		line-height:35px;
	  }
	  .bedrooms-filter-inner {
		span {
		  background-image:url($sprite-image);
		  width:37px;
		  height:36px;
		  float:left;
		  &.minus-btn {
			background-position:-226px -339px;
		  }
		  &.plus-btn {
			background-position:-230px -397px;
		  }
		}
		input {
		   float:left;
		   height:35px;
		   @include headline($color-primary, $font_15);
		   width:65px;
		}

	  }
	}
	.loaction-filter {
	  .sub-filter-btn {
		background-color:rgb(249, 248, 245);
		border: solid 1px rgb(238, 238, 238);
		width:100%;
		height:48px;
		line-height:48px;
		display:block;
		@include border-radius(24px);
		@include headline(rgb(202, 202, 202), $font_13);
		padding:0 30px 0 20px;
		margin:15px 0 40px;
		position:relative;
		&.greenTxt{
			color:$color-primary;
		}
		i {
		  background: url($sprite-image) no-repeat;
		  background-position: -244px -467px;
		  width:7px;
		  height:12px;
		  position:absolute;
		  top:50%;
		  @include translate(0,-50%);
		  right:20px;
		}
	  }
	}
	.amenities-filter {
	  h3 {
		line-height:18px;
	  }
	  .amenities-list {
		padding-bottom:110px;
		padding-top:6px;
		&.top-location {
			padding-bottom:20px;
		}
		li {
		  display:block;
		  line-height:27px;
		  padding: 15px 0;
		  border-bottom: 1px solid #f2f2f2;
		  text-transform: capitalize;
		  label {
			display:block;
			@include headline($color-dark-grey, $font_14);
		  }
		  i {
			background-image:url($amenities-sprite);
			background-repeat:no-repeat;
			width:26px;
			height:26px;
			float:left;
			margin-right:8px;       
			  &.amenities-icon-id-1 {
				background-position: -11px -11px;
			  }
			  &.amenities-icon-id-14 {
				background-position: -11px -48px;
			  }
			  &.amenities-icon-id-53 {
				background-position: -12px -86px;
			  }
			  &.amenities-icon-id-52 {
				background-position: -11px -125px;
			  }
			  &.amenities-icon-id-44 {
				background-position: -13px -174px;
			  }
			  &.amenities-icon-id-9 {
				background-position: -12px -221px;
			  }
			  &.amenities-icon-id-2 {
				background-position: -14px -267px;
			  }
			  &.amenities-icon-id-15 {
				background-position: -15px -310px;
			  }
			  &.amenities-icon-id-54 {
				background-position: -13px -349px;
			  }
			  &.amenities-icon-id-51 {
				background-position: -13px -386px;
			  }
			  &.amenities-icon-id-46 {
				background-position: -14px -433px;
			  }
			  &.amenities-icon-id-39 {
				background-position: -14px -433px;
			  }
			  &.amenities-icon-id-41 {
				background-position: -14px -469px;
			  }
			  &.amenities-icon-id-42 {
				background-position: -14px -469px;
			  }
			  &.amenities-icon-id-3 {
				background-position: -61px -10px;
			  }
			  &.amenities-icon-id-16 {
				background-position: -62px -49px;
			  }
			  &.amenities-icon-id-55 {
				background-position: -61px -92px;
			  }
			  &.amenities-icon-id-45 {
				background-position: -117px -13px;
			  }
			  &.amenities-icon-id-12 {
				background-position: -58px -182px;
			  }
			  &.amenities-icon-id-35 {
				background-position: -58px -225px;
			  }
			  &.amenities-icon-id-4 {
				background-position: -60px -272px;
			  }
			  &.amenities-icon-id-23 {
				background-position: -61px -320px;
			  }
			  &.amenities-icon-id-58 {
				background-position: -60px -369px;
			  }
			  &.amenities-icon-id-50 {
				background-position: -60px -411px;
			  }
			  &.amenities-icon-id-36 {
				background-position: -59px -454px;
			  }
			  &.amenities-icon-id-5 {
				background-position: -117px -13px;
			  }
			  &.amenities-icon-id-37 {
				background-position: -116px -65px;
			  }
			  &.amenities-icon-id-46 {
				background-position: -117px -111px;
			  }
			  &.amenities-icon-id-49 {
				background-position: -117px -156px;
			  }
			  &.amenities-icon-id-33 {
				background-position: -116px -203px;
			  }
			  &.amenities-icon-id-7 {
				background-position: -115px -250px;
			  }
			  &.amenities-icon-id-38 {
				background-position: -116px -289px;
			  }
			  &.amenities-icon-id-34 {
				background-position: -115px -325px;
			  }
			  &.amenities-icon-id-13 {
				background-position: -115px -367px;
			  }
			  &.amenities-icon-id-8 {
				background-position: -116px -413px;
			  }
			  &.amenities-icon-id-41 {
				background-position: -115px -461px;
			  }
			  &.amenities-icon-id-57 {
				background-position: -166px -16px;
			  }
			  &.amenities-icon-id-56 {
				background-position: -166px -66px;
			  }

		  }
		  input {
			height:0;
			width:0;
			display:block;
			visibility:hidden;
			+ span {
			  width:27px;
			  height:27px;
			  display:block;
			  background:url($sprite-image) no-repeat;
			  background-position: -288px -14px;
			}
			&:checked + span{
			  background-position: -288px -69px;
			}
		  }
		  &.area{
			display:none;
			&.active {
			  display:block;
			}
		  }
		}
		&.no-border {
			padding-top:7px;
			li {
				border:none;
				padding:8px 0;
			}
		}
	  }
	}
  }
}
.location-list {
	.location-search-input-outer {
		background:$genric-color-w;
		position:fixed;
		left:0px;
		width:100%;
		padding: 0 15px;
		top:59px;
		z-index:1000;
		.location-search-input {
			background-color:rgb(249, 248, 245);
			border: solid 1px rgb(238, 238, 238);
			width:100%;
			@include border-radius(5px);
			padding: 13px 18px;
			height:48px;
			position:relative;
			span {
				width:44px;
				height:44px;
				position:absolute;
				right:0;
				top:-1px;
				&::after {
					content:'';
					background: url('../img/search_icon.png') no-repeat;
					//background-position: -293px -182px;
					background-size: 22px;
					width:22px;
					height:22px;
					position:absolute;
					right:14px;
					top:50%;
					@include translateY(-50%);
				}
				&.active {
					&::after {
						background:url($sprite-image);
						background-position: -129px -236px;
						width:16px;
						height:16px;
					}
				}
			}
			input {
				line-height:18px;
				height:20px;
				margin:0;
				width:80%;
				background:transparent;
				@include headline($color-dark-grey, $font_13);
					&::-webkit-input-placeholder {
					@include font-weight(thin);
					opacity:1 !important;
					font-weight: 500;
					@include headline(rgb(202, 202, 202) !important, $font_16);
					text-decoration:none;
				}
		  }
		}
	}
  .modal-body {
	// overflow-y:hidden !important;
	padding-top:44px;
	h3 {
	  position:relative;
	  padding-top:15px;
	  line-height:24px !important;
	  &.location-group-hide {
		display:none !important;
	  }
	}
	.transparent-gredient {
	  bottom:-29px;
	}
	.selected_top_location_container {
		display:none;
	}
	.location-group {
	  padding-top:25px;
	  &.location-group-hide {
		display:none;
	  }
	  ul {
		margin-right:-10px;
	  }
	  li {
		height:34px;
		line-height:34px;
		@include border-radius(17px);
		padding: 0 17px;
		display:inline-block;
		border:1px solid $color-primary;
		margin:0 10px 10px 0;
		@include headline($color-primary, $font_14);
		&.active {
		  background-color: $color-primary;
		  color:$genric-color-w;
		}
	  }
	}
	.amenities-list {
	  padding-top:12px !important;
	}
  }
}


.input-range__slider {
	background:url($sprite-image);
	background-position:-176px -460px;
	width: 31px;
	height: 31px;
	margin-left: -17px;
	margin-top: -21px;
	// @include border-radius(50%);

}
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
            transform: none; 
    }

.input-range__slider-container {
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out; }

.input-range__label {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	white-space: nowrap;
	@include headline($color-primary, $font_14);
	display:none;
}

.input-range__label--min,
.input-range__label--max {
	bottom: -35px;
	position: absolute; 
}

.input-range__label--min {
	left: 0; 
}

.input-range__label--max {
	right: 0; 
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem; 

}

.input-range__label-container {
  left: -50%;
  position: relative; 
}
  .input-range__label--max .input-range__label-container {
    left: 50%; 

}

.input-range__track {
	cursor: pointer;
	display: block;
	height: 13px;
	position: relative;
	-webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
	transition: left 0.3s ease-out, width 0.3s ease-out;
	background-color: #efefef;
	-webkit-box-shadow: inset 0 1px 3px 0 rgba(203, 203, 203, 0.5);
	box-shadow: inset 0 1px 3px 0 rgba(203, 203, 203, 0.5);
	border-radius: 6px !important;
}
  .input-range--disabled .input-range__track {
    background: #eeeeee; }

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%; }

.input-range__track--active {
  background: #00ba8c; 
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%; }

.price-label-min,
.price-label-max {
	@include headline($color-primary, $font_14);
	margin-top:14px;
}
.price-label-min {
	float:left;
	margin-left:-12px;
}
.price-label-max {
	float:right;
	margin-right:-17px;
}
/*# sourceMappingURL=react-input-range.css.map*/